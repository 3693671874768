<template>
  <game-100m-component />
</template>

<script lang="ts">
import Game100mComponent from '@/components/Game100mComponent.vue'

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    Game100mComponent
  },
})

</script>

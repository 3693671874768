import { fpsManager } from '@powerplay/core-minigames'
import {
  velocityConfig,
  startConfig
} from '../config'
import { wind } from '../entities/athlete/Wind'
import { disciplinePhasesManager } from '../phases/DisciplinePhasesManager'

/**
 * Manager rychlosti hraca
 */
export class SpeedManager {

  /** ci je speedManager aktivny */
  private active = false

  /** momentalna rychlost v m/s */
  private actualSpeed = 0

  /** momentalna rychlost v cieli v m/s */
  private finishSpeedActual = 0

  /** hodnota na odcitanie rychlosti v cieli */
  private finishSpeedSub = 0

  /** minimalna hodnota rychlosti v cieli */
  private finishSpeedMin = 0

  /** Hodnota pre znizenie kazdy frame pri false starte, aby hrac spomaloval */
  private falseStartSpeedDecrease = 0

  /** Callback pri dosiahnuti min rychlosti */
  private finishSpeedCallback: (() => unknown) | undefined

  /**
   * Vracia rychlost v metroch za sekundu
   * @returns actualSpeed m/s
   */
  public getActualSpeed(): number {

    return this.actualSpeed

  }

  /**
   * Setter
   * @param speed - nova rychlost
   */
  public setActualSpeed(speed: number): void {

    this.actualSpeed = speed

  }

  /**
   * Nastavi specialne spravanie rychlosti pre false start
   */
  public setSpeedFalseStart(): void {

    this.falseStartSpeedDecrease = startConfig.falseStart.decreaseSpeedValue / fpsManager.fpsLimit

  }

  /**
   * Vracia rychlost v metroch za frame
   * @returns actualSpeedPerFrame m/f
   */
  public getActualSpeedPerFrame(): number {

    return this.actualSpeed / fpsManager.fpsLimit

  }

  /**
   * Vracia rychlost v metroch za frame v cieli
   * @returns actualSpeedPerFrame m/f
   */
  public getActualSpeedPerFrameOnEnd(): number {

    this.finishSpeedActual -= this.finishSpeedSub
    if (this.finishSpeedActual < this.finishSpeedMin) {

      this.finishSpeedActual = this.finishSpeedMin
      if (this.finishSpeedCallback) {

        const callback = this.finishSpeedCallback
        this.finishSpeedCallback = undefined
        callback()

      }

    }

    return this.finishSpeedActual / fpsManager.fpsLimit

  }

  /**
   * Nastavenie dat pre spomalovanie v cieli
   * @param from - Pociatocna rychlost
   * @param to - Konecna rychlost
   * @param frames - Pocet frameov na dosiahnutie konecnej rychlosti
   * @param callback - callback pri dosiahnuti konecnej rychlosti
   */
  public setDataForSpeedOnEnd(
    from: number,
    to: number,
    frames: number,
    callback: () => unknown
  ): void {

    this.finishSpeedActual = from
    this.finishSpeedMin = to
    this.finishSpeedSub = (from - to) / frames
    this.finishSpeedCallback = callback

  }

  /**
   * Setter active
   * @param active - active
   */
  public setActive(active: boolean): void {

    this.active = active

  }

  /**
   * Vratenie, ci je aktivna rychlost
   * @returns True, ak je aktivna
   */
  public isActive(): boolean {

    return this.active

  }

  /**
   * Ziskanie globalMaxSpeed
   * @param attribute - Atribut hraca
   * @returns globalMaxSpeed
   */
  private getGlobalMaxSpeed(attribute: number): number {

    // default pre atribut viac ako 1000
    let speed = 12 + ((attribute - 1000) * 0.00025)

    if (attribute <= 100) {

      speed = 9.2 + (attribute * 0.01)

    } else if (attribute <= 1000) {

      speed = 10 + (attribute * 0.002)

    }

    // vietor este pridame
    speed += (wind.getValue() * 0.05)

    return speed

  }

  /**
   * Ziskanie localMaxSpeed
   * @param minIdealValue - Minimalna hodnota idealu
   * @param globalMaxSpeed - globalMaxSpeed
   * @returns localMaxSpeed
   */
  public getLocalMaxSpeed(minIdealValue: number, globalMaxSpeed: number): number {

    return (0.5 + ((minIdealValue - 110) / 160)) * globalMaxSpeed

  }

  /**
   * Sprava znizovania rychlosti pri false starte
   * @returns True, ak sa znizuje rychlost
   */
  private manageFalseStartSpeedDecrease(): boolean {

    if (this.falseStartSpeedDecrease <= 0) return false

    const minSpeed = startConfig.falseStart.minSpeed
    this.actualSpeed -= this.falseStartSpeedDecrease
    if (this.actualSpeed < minSpeed) this.actualSpeed = minSpeed

    return true

  }

  /**
   *
   * @param speedbarValue - aktualna hodnota speed baru
   * @param minIdealValue - minimalna hodnota idealu
   * @param attribute - atribut hraca
   */
  private setSpeed(speedbarValue: number, minIdealValue: number, attribute: number): void {

    // default sme v ideali
    let localMaxSpeed
    let speed = localMaxSpeed = this.getLocalMaxSpeed(minIdealValue, this.getGlobalMaxSpeed(attribute))

    if (speedbarValue < minIdealValue) {

      speed *= (speedbarValue / minIdealValue)

    }

    if (speedbarValue > velocityConfig.speedBar.maxIdealValue) {

      speed *= ((100 - (speedbarValue - velocityConfig.speedBar.maxIdealValue) / 2) / 100)

    }

    this.actualSpeed = speed

    if (!this.active) return
    disciplinePhasesManager.phaseRunning.collectPartQualityRun(localMaxSpeed === speed ? 1 : 0)

  }

  /**
   * Aktualizovanie veci kazdy frame
   * @param speedbarValue - aktualna hodnota speed baru
   * @param minIdealValue - minimalna hodnota idealu
   * @param attribute - atribut hraca
   */
  public update(speedbarValue: number, minIdealValue: number, attribute: number): void {

    if (this.manageFalseStartSpeedDecrease()) return

    this.setSpeed(speedbarValue, minIdealValue, attribute)

  }

  /**
   * reset
   */
  public reset(): void {

    this.actualSpeed = 0
    this.active = false
    this.falseStartSpeedDecrease = 0
    this.finishSpeedActual = 0
    this.finishSpeedSub = 0
    this.finishSpeedMin = 0

  }

}

import type { AudioObject } from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'

export const audioSprites: AudioObject[] = [
  {
    files: [
      AudioNames.spriteFootsteps
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    // subset: true,
    // randomizeSprite: true,
    category: AudioCategories.sprite,
    version: 2,
    sprite: {
      'footstep-player1': [
        0,
        384.01360544217687
      ],
      'footstep-player10': [
        2000,
        359.9999999999999
      ],
      'footstep-player2': [
        4000,
        384.01360544217675
      ],
      'footstep-player3': [
        6000,
        360.00000000000034
      ],
      'footstep-player4': [
        8000,
        384.01360544217766
      ],
      'footstep-player5': [
        10000,
        335.98639455782296
      ],
      'footstep-player6': [
        12000,
        335.98639455782296
      ],
      'footstep-player7': [
        14000,
        335.98639455782296
      ],
      'footstep-player8': [
        16000,
        359.99999999999943
      ],
      'footstep-player9': [
        18000,
        384.01360544217766
      ]
    }
  },
  {
    files: [
      AudioNames.spriteAudienceNotLooped
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    category: AudioCategories.sprite,
    group: AudioGroups.audience,
    version: 2,
    sprite: {
      'audience-bad-overlay-3': [
        0,
        3048.0045351473923
      ],
      'audience-hyped-overlay-5': [
        5000,
        15168.004535147393
      ]
    }
  },
  {
    files: [
      AudioNames.spriteCommentators
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    ignoreModes: [3, 14, 9, 10],
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.sprite,
    randomizeSprite: true,
    group: AudioGroups.commentators,
    subset: true,
    sprite: {
      'VO_100meters_false_start': [
        0,
        4966.326530612245
      ],
      'VO_100meters_good_start': [
        6000,
        5079.07029478458
      ],
      'VO_100meters_perfect_start_var01': [
        13000,
        4162.471655328797
      ],
      'VO_100meters_perfect_start_var012': [
        19000,
        3789.410430839002
      ],
      'VO_100meters_personal_best': [
        24000,
        7230.068027210884
      ],
      'VO_100meters_poor_start': [
        33000,
        5184.013605442175
      ],
      'VO_100meters_rank_1_3_var01': [
        40000,
        6591.269841269842
      ],
      'VO_100meters_rank_1_3_var012': [
        48000,
        5371.56462585034
      ],
      'VO_100meters_rank_1_var01': [
        55000,
        4914.625850340137
      ],
      'VO_100meters_rank_1_var012': [
        61000,
        6114.104308390026
      ],
      'VO_100meters_rank_2_3': [
        69000,
        3911.0204081632673
      ],
      'VO_100meters_rank_4_5': [
        74000,
        6568.367346938771
      ],
      'VO_100meters_rank_6': [
        82000,
        6076.258503401363
      ],
      'VO_100meters_second_false_start': [
        90000,
        7261.950113378688
      ],
      'VO_100meters_world_record': [
        99000,
        8775.102040816322
      ]
    }
  }
]
import {
  minigameConfig,
  PlayerRace,
  PlayerSex,
  type PlayerInfo
} from '@powerplay/core-minigames'

/**
 * Fake superi pre tutorial, trening a dennu ligu
 */
export const fakeOpponents: PlayerInfo[] = [
  {
    uuid: 'uuid1',
    name: 'Noah',
    country: 'usa',
    countryString: 'usa',
    sex: PlayerSex.male,
    race: PlayerRace.black,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuid2',
    name: 'Evelyn',
    country: 'jam',
    countryString: 'jam',
    sex: PlayerSex.female,
    race: PlayerRace.black,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuid3',
    name: 'Donovan',
    country: 'can',
    countryString: 'can',
    sex: PlayerSex.male,
    race: PlayerRace.black,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuid4',
    name: 'Victoria',
    country: 'gbr',
    countryString: 'gbr',
    sex: PlayerSex.female,
    race: PlayerRace.black,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuid5',
    name: 'Richard',
    country: 'tto',
    countryString: 'tto',
    sex: PlayerSex.male,
    race: PlayerRace.black,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuid6',
    name: 'Christine',
    country: 'civ',
    countryString: 'civ',
    sex: PlayerSex.female,
    race: PlayerRace.black,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
  {
    uuid: 'uuid7',
    name: 'Jamal',
    country: 'nga',
    countryString: 'ngr',
    sex: PlayerSex.male,
    race: PlayerRace.black,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  },
]

/**
 * attribute strength pre superov - trening
 */
export const trainingOpponentsAttributeStrengths = [
  0.89, 0.88, 0.87, 0.86, 0.85, 0.84, 0.83
]

/**
 * attribute strength pre superov - denna liga
 */
export const dailyLeagueOpponentsAttributeStrengths = [
  0.9, 0.9, 0.9, 0.9, 0.9, 0.9, 0.9
]


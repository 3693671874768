import { LungeStates } from '../types'
import { THREE } from '@powerplay/core-minigames'

/**
 * Konfig pre lunge
 */
export const lungeConfig = {

  /** Pocet frameov pre jednotlive stavy pre lunge */
  frames: {
    [LungeStates.none]: 0,
    [LungeStates.forward]: 8,
    [LungeStates.stay]: 4,
    [LungeStates.backward]: 8,
  },

  /** Pocet metrov pred cielom, kedy je dostupny lunge */
  distanceBeforeFinish: 10,

  /** Maximalny preklon v metroch */
  lungeOffsetMax: 0.3,

  /** coef na slowmo */
  speedLockCoefCameraChange: 0.1,

  /** coef na slowmo */
  speedLockCoefFinishLine: 0.8,

  /** offset kamery pri speedLocku */
  cameraOffset: new THREE.Vector3(-1, 2, 0),

  /** cas v s za aky sa presunie kamera */
  cameraTransitionDuration: 2,

  /** ci je povoleny speedLock a side kamera */
  speedLockEnabled: true

}

<template>
  <div
    v-if="showTable"
    class="positioner"
  >
    <power-table
      :row-data="dataTable"
      :header-props="headerProps()"
      :active="active"
      :is-training="isTraining"
      :is-start-list="isStartList"
      :show-discipline-icon="showDisciplineIcon"
      :show-ranking-points-ssm="showRankingPoints"
      :special-record="specialRecord"
      class="transform-origin-top-center"
      @mousedown="oneClick"
      @touchstart="oneClick"
    />
  </div>
</template>

<script lang="ts">
import {
  requestManager,
  modes,
  playersManager,
  timeManager,
  minigameConfig
} from '@powerplay/core-minigames'
import { PowerTable } from '@powerplay/core-minigames-ui-ssm'
import { mapGetters } from 'vuex'
import { pathAssetsCoreUi } from '@/globals/globalvariables'

import { defineComponent } from 'vue'
import { player } from '@/app/entities/athlete/player/Player'
import { gameConfig } from '@/app/config'
import { wind } from '@/app/entities/athlete/Wind'

export default defineComponent({
  name: 'DataTable',
  components: {
    PowerTable
  },
  data() {

    return {
      active: false,
      specialRecord: '',
      isPB: false,
      firstClick: false
      /*
       * headerProps: {
       *  // time: '3:12:05',
       *  discipline: {
       *      name: 'Downhill',
       *      image: `${pathAssets}/ui/table/ICO-DISCIPLINE.png`
       *  },
       *  // competitonType: 'Test hill'
       *  resultType: this.$t('')
       *  // add: {
       *  //     image: `${pathAssets}/ui/table/Logo-Default.png`,
       *  //     alt: 'Add-logo'
       *  // }
       * }
       */
    }

  },
  computed: {
    ...mapGetters({
      showTable: 'TableState/getShowTable',
      isStartList: 'TableState/getIsStartList',
      activeState: 'TableState/getActiveState',
      dataTable: 'TableState/getDataTable'
    }),
    isTraining() {

      return modes.isTrainingMode()

    },
    showDisciplineIcon() {

      return modes.isBossCompetition()

    },
    showRankingPoints() {

      return !modes.isBossCompetition() && !modes.isEventBossFight()

    }
  },
  watch: {
    activeState: {
      handler(value: boolean) {

        setTimeout(() => {

          this.active = value

        }, 10)


      }
    }
  },
  methods: {
    oneClick(event: Event) {

      if (this.activeState) event.stopPropagation()

    },
    headerProps() {

      let personalBest = playersManager.getPlayer().personalBest
      if (personalBest === 0 && player.finalTime !== minigameConfig.dnfValue) personalBest = minigameConfig.dnfValue
      const result = Math.ceil(player.finalTime * 100) / 100
      let personalBestValue = personalBest
      if (this.$store.getters['TableState/getResultText'] === 'finalStandings') {

        if (personalBest >= result) {

          this.specialRecord = 'PB'
          this.isPB = true

        }
        if (gameConfig.worldRecord >= result) this.specialRecord = 'WR'
        personalBestValue = personalBest > result ? result : personalBest

      }
      let headerTableText = {}
      if (this.$store.getters['TableState/getResultText'] !== 'startList') {

        headerTableText = {
          col3: this.$t('Result')
        }

      }

      return {
        discipline: {
          name: this.$t(`disciplineName${requestManager.disciplineID}`),
          image: `${pathAssetsCoreUi}/table/attribute-icon/disc-ico-light-01.svg`
        },
        competitonType: this.$t(this.$store.getters['TableState/getCompetitionText']),
        resultType: this.$t(this.$store.getters['TableState/getResultText']),
        wind: {
          show: this.$store.getters['TableState/getResultText'] === 'finalStandings' ? true : false,
          value: `${wind.getValueWithSignAsString()}`
        },
        headerTableText,
        personalBest: {
          showNew: this.isPB,
          value: personalBestValue === 0 ? '-' : timeManager.getTimeInFormatFromSeconds(personalBestValue, 2)
        },
      }

    }
  }

})

</script>

<style scoped lang="less">
.positioner {
  position: absolute;
  top: 13%;
  left: 50%;
  transform: translateX(-50%);

  .transform-origin-top-center {
      transform-origin: top center;
  }
}
</style>

import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/**
 * Konfig pre kameru pre vsetky stavy
 */
export const cameraConfig = {

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(80.5832290649414, 20.418212890625, -14.19685173034668),
      endPosition: new THREE.Vector3(78.27311706542969, 17.48335838317871, 5.406519412994385),
      startRotation: new THREE.Vector3(1.7148397276850365, 0.28575224940917443, -2.2085366520588057),
      endRotation: new THREE.Vector3(1.6868902353549138, 0.2904939682288854, -2.1234438840217393),
      duration: 3,
      notSkippableFrames: 20,
      fov: 56
    },
    [CameraStates.intro2]: {
      startPosition: new THREE.Vector3(35.8325080871582, 3.460610866546631, 22.722057342529297),
      endPosition: new THREE.Vector3(18.410503387451172, 22.469364166259766, -56.212440490722656),
      startRotation: new THREE.Vector3(1.6622260099005624, -0.06868950949355988, -0.18408679411530016),
      endRotation: new THREE.Vector3(1.857671851508601, 0.06078303524747545, -0.18079813741701375),
      duration: 3,
      notSkippableFrames: 20,
      fov: 49
    },
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(65.35472869873047, 29.06309700012207, -63.67209243774414),
      endPosition: new THREE.Vector3(52.92066955566406, 15.357963562011719, -68.88593292236328),
      startRotation: new THREE.Vector3(1.8531298163809546, 0.16892826638428243, -2.774806166746781),
      endRotation: new THREE.Vector3(1.8250801965805064, 0.1218453008704289, -2.88817661305781),
      duration: 5
    },
    [CameraStates.disciplineIntro]: {
      startPosition: new THREE.Vector3(0.19059371948, 1.15008175373, -4.4945793),
      endPosition: new THREE.Vector3(-0.35896682739, 1.1644126179864, -4.0386695861),
      startRotation: new THREE.Vector3(1.5403486, -0.006517394, -3.09213628),
      endRotation: new THREE.Vector3(1.548322, 0.005684515, 3.05518974),
      duration: 3,
      fov: 36.4,
      offsetCamera: true
    },
    [CameraStates.disciplineIntroSecond]: {
      startPosition: new THREE.Vector3(72.53616, 27.7868595, 55.91532),
      endPosition: new THREE.Vector3(68.45848, 24.56152, 55.79915),
      startRotation: new THREE.Vector3(1.5921732, 0.66949988798, -1.56024659),
      endRotation: new THREE.Vector3(1.5921732, 0.66949988798, -1.56024659),
      duration: 3,
      fov: 36.4
    },
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: {
      // startPosition: new THREE.Vector3(-2, 2, 3),
      startPosition: new THREE.Vector3(6, 2, 0),
      // endPosition: new THREE.Vector3(2, 2, 3),
      endPosition: new THREE.Vector3(6, 2, 0),
      duration: 10,
      offsetCamera: true
    },
    [CameraStates.static]: undefined
  },
  distanceFromGround: 1.5,
  dollyZoom: {

    // fov ktory bude dosiahnuty pri dvojnasobnej rychlosti od min rychlosti zapisanej nizsie
    maxFov: 70,

    // od akej rychlosti riesit fov
    minSpeed: 6,

    // aky velky bude rozdiel medzi pociatocnym a koncovym idealnym offsetom
    coefDiffIdealOffset: 3,

    // aky velky bude rozdiel medzi pociatocnym a koncovym idealnym offsetom pri maximalnom fov
    coefDiffIdealOffsetMaxFov: 3.6

  },

  // zablesky fotakov
  flashes: {

    // zablesky budu kazdy x-ty frame
    everyXframe: 3,

    // Aky bude posun offsetu
    offsetShift: {
      min: 0.01,
      max: 0.03
    }

  },

  // cielova kamera
  finish: {

    // koeficient, ktorym sa prenasobuje pohyb kamery po zastaveni hraca, aby bol dobeh
    coefSlowDownAfterPlayerStopMoving: 0.95,

    // Hodnota o ktoru sa znizuje hodnota zoomu z max hodnoty kazdy frame
    zoomChangeStep: 0.03,

    // Hodnota o ktoru sa znizuje hodnota zoomu z max hodnoty kazdy frame - potom co hrac zastavi
    zoomChangeStepAfterPlayerStopMoving: 0.005,

    // Max hodnota zoomu pocas zmeny fazy na finish
    zoomMaxValue: 9,

    // Staticka pozicia kamery v cieli
    positionCamera: new THREE.Vector3(39, 1.18, -81),

    // Delay vo frameoch, kedy sa az zobrazi tato kamera
    delayFrames: 25

  }

}

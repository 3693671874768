import {
  pathAssetsGlobal,
  pathAssets
} from '@/globals/globalvariables'
import type { LoaderDataTypes } from '@powerplay/core-minigames'
import { TexturesNames } from '../types'

// Hlavny priecinok s texturami
const texturesDir = `${pathAssets}/textures/`
const texturesDirGlobal = `${pathAssetsGlobal}/textures/`

/**
 * Konfig pre textury
 */
export const texturesConfig: LoaderDataTypes = {
  [TexturesNames.athleteRaceWhiteMan]: {
    ext: 'ktx2',
    version: 231206,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteRaceMulattoMan]: {
    ext: 'ktx2',
    version: 231206,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteRaceBlackMan]: {
    ext: 'ktx2',
    version: 231206,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteRaceWhiteWoman]: {
    ext: 'ktx2',
    version: 231206,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteRaceMulattoWoman]: {
    ext: 'ktx2',
    version: 231206,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteRaceBlackWoman]: {
    ext: 'ktx2',
    version: 231206,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteClothes]: {
    ext: 'ktx2',
    genderActive: true,
    version: 5,
    femaleVersion: 4,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteClothesOpponent1]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 4,
    femaleVersion: 3,
    dir: `${texturesDir}/athlete/`,
    ignoreInModes: [9, 10]
  },
  [TexturesNames.athleteClothesOpponent2]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 4,
    femaleVersion: 3,
    dir: `${texturesDir}/athlete/`,
    ignoreInModes: [9, 10]
  },
  [TexturesNames.athleteClothesOpponent3]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 4,
    femaleVersion: 3,
    dir: `${texturesDir}/athlete/`,
    ignoreInModes: [9, 10]
  },
  [TexturesNames.athleteClothesOpponent4]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 4,
    femaleVersion: 3,
    dir: `${texturesDir}/athlete/`,
    ignoreInModes: [9, 10]
  },
  [TexturesNames.athleteClothesOpponent5]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 4,
    femaleVersion: 3,
    dir: `${texturesDir}/athlete/`,
    ignoreInModes: [9, 10]
  },
  [TexturesNames.athleteClothesOpponent6]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 4,
    femaleVersion: 3,
    dir: `${texturesDir}/athlete/`,
    ignoreInModes: [9, 10]
  },
  [TexturesNames.athleteClothesOpponent7]: {
    ext: 'ktx2',
    genderActive: true,
    opponent: true,
    version: 4,
    femaleVersion: 3,
    dir: `${texturesDir}/athlete/`,
    ignoreInModes: [9, 10]
  },
  [TexturesNames.athleteHairMan]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.athleteHairWoman]: {
    ext: 'ktx2',
    version: 20230927,
    dir: `${texturesDir}/athlete/`
  },
  [TexturesNames.audience]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.colorGradient]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.colorGradientVshosting]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.concrete]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.glassPanel]: {
    ext: 'ktx2',
    dir: `${texturesDir}/env/`
  },
  [TexturesNames.grass]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.hexCladding]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.metal]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.panel]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.sand]: {
    ext: 'ktx2',
    dir: `${texturesDir}/env/`
  },
  [TexturesNames.seats]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.stairs]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.lattice]: {
    ext: 'ktx2',
    dir: `${texturesDir}/env/`
  },
  [TexturesNames.emissive]: {
    ext: 'ktx2',
    dir: `${texturesDir}/env/`
  },
  [TexturesNames.flagsImages]: {
    ext: 'ktx2',
    version: 240610,
    dir: `${texturesDir}/env/`
  },
  [TexturesNames.trackTexts]: {
    ext: 'ktx2',
    dir: `${texturesDir}/env/`
  },
  [TexturesNames.gradient]: {
    ext: 'ktx2',
    dir: `${texturesDir}/env/`
  },
  [TexturesNames.npc]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.lightFlashes]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.numbers]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.camera]: {
    ext: 'ktx2',
    dir: `${texturesDir}/env/`
  },
  [TexturesNames.alphabet]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.skybox]: {
    ext: 'ktx2',
    dir: `${texturesDirGlobal}/env/`
  },
  [TexturesNames.envLightmap]: {
    ext: 'ktx2',
    dir: `${texturesDir}/env/`,
    version: 231110,
    returnTextureLightmap: true
  },
  [TexturesNames.envLightmapDark]: {
    ext: 'ktx2',
    dir: `${texturesDir}/env/`,
    version: 231110,
    returnTextureLightmap: true
  },

}

/** Mena vsetkych modelov, ktore sa pouzivaju v minihre */
export enum ModelsNames {

  athlete = 'athlete',
  env = 'env',
  envDynamic = 'envDynamic',
  trackLines = 'trackLines',
  stadium = 'AthleticStadium',
  cameraBoxes = 'cameraBoxes'

}

/** Mena vsetkych textur, ktore sa pouzivaju v minihre */
export enum TexturesNames {

  athleteRacePrefix = 'athlete_race_',
  athleteRaceWhiteMan = 'male/athlete_race_white',
  athleteRaceMulattoMan = 'male/athlete_race_mulatto',
  athleteRaceBlackMan = 'male/athlete_race_black',
  athleteRaceWhiteWoman = 'female/athlete_race_white',
  athleteRaceMulattoWoman = 'female/athlete_race_mulatto',
  athleteRaceBlackWoman = 'female/athlete_race_black',
  athleteClothes = 'athlete_clothes',
  athleteClothesOpponent1 = 'athlete_clothes_opponent_1_v',
  athleteClothesOpponent2 = 'athlete_clothes_opponent_2_v',
  athleteClothesOpponent3 = 'athlete_clothes_opponent_3_v',
  athleteClothesOpponent4 = 'athlete_clothes_opponent_4_v',
  athleteClothesOpponent5 = 'athlete_clothes_opponent_5_v',
  athleteClothesOpponent6 = 'athlete_clothes_opponent_6_v',
  athleteClothesOpponent7 = 'athlete_clothes_opponent_7_v',
  athleteHairMan = 'male/athlete_hair',
  athleteHairWoman = 'female/athlete_hair',
  audience = 'audience512v',
  colorGradient = 'ColorGradientAds2048v',
  colorGradientVshosting = 'ColorGradientAdsVshosting2048',
  concrete = 'CleanConcrete512',
  glassPanel = 'GlassPanel512',
  grass = 'Grass512',
  ground = 'GroundTexture512',
  hexCladding = 'HexCladding512',
  lights = 'Lights512',
  metal = 'MetalDetail512',
  panel = 'Panel512',
  sand = 'Sand512',
  seats = 'Seats512v',
  stairs = 'Stairs512',
  texts = 'Texts1024v',
  lattice = 'Lattice512',
  emissive = 'Emissive512',
  flagsImages = 'FlagsImages2048v',
  flagsTexts = 'FlagsTexts2048v',
  trackTexts = 'TrackTexts1024',
  gradient = 'Gradient64',
  numbers = 'NumbersTemp512',
  npc = 'NPC512',
  camera = 'Camera128',
  alphabet = 'Alphabet1024',
  lightFlashes = 'LightFlashes512',
  envLightmap = 'EnvLightmap2048v',
  envLightmapDark = 'EnvLightmapDark2048v',
  skybox = 'SkyboxNight1024'

}

/** Mena vsetkych materialov, ktore sa pouzivaju v minihre */
export enum MaterialsNames {

  athlete = 'athlete',
  athleteOpponentPrefix = 'athlete_opponent_',
  athleteOpponent1 = 'athlete_opponent_1',
  athleteOpponent2 = 'athlete_opponent_2',
  athleteOpponent3 = 'athlete_opponent_3',
  athleteOpponent4 = 'athlete_opponent_4',
  athleteOpponent5 = 'athlete_opponent_5',
  athleteOpponent6 = 'athlete_opponent_6',
  athleteOpponent7 = 'athlete_opponent_7',
  athleteHairTrack1 = 'athleteHairTrack1',
  athleteHairTrack2 = 'athleteHairTrack2',
  athleteHairTrack3 = 'athleteHairTrack3',
  athleteHairTrack4 = 'athleteHairTrack4',
  athleteHairTrack5 = 'athleteHairTrack5',
  athleteHairTrack6 = 'athleteHairTrack6',
  athleteHairTrack7 = 'athleteHairTrack7',
  athleteHairTrack8 = 'athleteHairTrack8',
  athleteHairClip = 'athleteHairClip',
  audience = 'audience',
  audienceFlags = 'audienceFlags',
  colorGradient = 'colorGradient',
  concrete = 'concrete',
  glassPanel = 'glassPanel',
  grass = 'grass',
  ground = 'ground',
  hexCladding = 'hexCladding',
  lights = 'lights',
  metal = 'metal',
  panel = 'panel',
  camera = 'camera',
  sand = 'sand',
  seats = 'seats',
  stairs = 'stairs',
  npc = 'npc',
  texts = 'texts',
  texts2 = 'texts2',
  lattice = 'lattice',
  emissive = 'emissive',
  numbers = 'numbers',
  lightFlashes = 'lightFlashes',
  lightsGradient = 'lightGradient',
  flagsImages1 = 'FlagsImages1',
  flagsImages2 = 'FlagsImages2',
  flagsImages3 = 'FlagsImages3',
  flagsImages4 = 'FlagsImages4',
  flagsImages5 = 'FlagsImages5',
  flagsImages6 = 'FlagsImages6',
  flagsImages7 = 'FlagsImages7',
  flagsImages8 = 'FlagsImages8',
  flagsTexts = 'FlagsTexts',
  flagsGradient = 'FlagsGradient',
  onlyVertexColors = 'onlyVertexColors',
  leadIndicator = 'leadIndicator',
  skybox = 'skybox'

}

/** Mena vsetkych animacii hraca */
export enum PlayerAnimationsNames {

  prepare = 'N prepare',
  prepare1 = 'N prepare 1',
  prepare2 = 'N prepare 2',
  prepare3 = 'N prepare 3',
  prepare4 = 'N prepare 4',
  prepareHand = 'N prepare 1 hand',
  prepareHands = 'N prepare 2 hands',
  prepareSalut = 'N prepare salut',
  prepareKisses = 'prepare kisses',
  idleGroundOnStart = 'N idle ground',
  groundToSet = 'N ground to set',
  setToRun = 'N set to run',
  startRun = 'N run start',
  run = 'N run',
  lunge = 'N lunge',
  slowDownRun = 'N run middle',
  endHappy1 = 'N end 1',
  endHappy2 = 'N end 2',
  endHappy3 = 'N end 4',
  stopToBreathing = 'N run stop',
  breathing = 'N breathing',

}

/**
 * Specialne data z init requestu
 */
export interface SpecialDataFromInit {

  nothing: unknown

}

/** Farby pre lidra */
export enum LeaderColors {

  green = 0,
  lightBlue = 1

}

/** Rozne eventy */
export enum CustomGameEvents {

  playerFinished = 'playerFinished'

}

import {
  THREE,
  AnimationsManager,
  game,
  fpsManager,
  playersManager,
  gsap,
  timeManager,
  minigameConfig,
  PlayerSex,
  modes,
  HairColorTypes
} from '@powerplay/core-minigames'
import {
  gameConfig,
  animationsConfig,
  velocityConfig,
  lungeConfig,
  startConfig,
  pathsConfig
} from '../../config'
import {
  DisciplinePhases,
  PlayerStates,
  EmotionTypesFinish,
  EndTypesFinish,
  LungeStates,
  Tasks,
  CustomGameEvents
} from '../../types'
import {
  AthleteAnimationManager,
  AthleteVelocityManager
} from '.'
import { WorldEnvLinesCreator } from '../env/WorldEnvLinesCreator'
import { SpeedManager } from '@/app/SpeedManager/SpeedManager'
import store from '@/store'
import { endManager } from '@/app/EndManager'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { trainingTasks } from '@/app/modes/training'
import { audioHelper } from '@/app/audioHelper/AudioHelper'

/**
 * Trieda pre atleta
 */
export class Athlete {

  /** speed lock */
  public speedLockActive = false

  /** 3D objekt lyziara - cela scena */
  public athleteObject = new THREE.Object3D()

  /** Zakladny animacny manager */
  public animationsManager!: AnimationsManager

  /** Manager pre animacne stavy */
  public athleteAnimationManager = new AthleteAnimationManager(this)

  /** Manager rychlosti */
  public velocityManager = new AthleteVelocityManager(this)

  /** manager rychlosti */
  public speedManager = new SpeedManager()

  /** Ci uz je v cieli alebo nie */
  public finished = false

  /** Stav hraca */
  private state = PlayerStates.prepare

  /** Ci je aktivne aktualizovanie pohybovych animacii */
  public activeUpdatingMovementAnimations = false

  /** Posledna pozicia atleta */
  public lastAthletePosition = new THREE.Vector3()

  /** dolezity manager na pohyb */
  public worldEnvLinesManager!: WorldEnvLinesCreator

  /** Ci ide o hratelneho atleta alebo nie */
  public playable = false

  /** Emocia v cieli */
  public emotionFinish = EmotionTypesFinish.looser

  /** Ukoncenie v cieli */
  public endPhaseTwoFinish = EndTypesFinish.walk

  /** Aktualny stav pre lunge */
  public lungeState = LungeStates.none

  /** Aktualny pocet frameov od zaciatku aktualneho stavu pre lunge */
  public lungeActualFrame = 0

  /** Nahodne cislo pre fazu dva, ci pojde walk alebo breathing */
  private randomNumberPhaseTwo = 0

  /** Kolko frameov treba pockat, aby sa nastavil stav konca */
  private framesToWaitForEndState = 0

  /** Ci sa ma aplikovat spomalovanie aj rychlosti animacie */
  private changeAnimationSpeedFalseStart = false

  /** Rychlost na zaciatku false startu spomalovania */
  private speedOnStartFalseStart = 0

  /** Ci sa maju aktualizovat framey (mali by sa po starte) */
  private updateFramesAfterStart = false

  /** Pocet frameov po starte */
  private framesAfterStart = 0

  /** Pocet frameov, kolko zostane v lunge casti, kde sa nemeni offset */
  private lungeFramesStay = 0

  /** Cas v cieli */
  public finalTime = minigameConfig.dnfValue

  /** ci sa ma spustit lunge same */
  public tutorialAutoLunge = false

  /**
   * Konstruktor
   * @param uuid - UUID spera
   */
  public constructor(public uuid = '') {}

  /**
   * Nastavenie stavu atleta
   * @param state - stav
   * @param maxRandomDelay - maximalna hodnota randomu delayu od 0
   */
  public setState(state: PlayerStates, maxRandomDelay = 0): void {

    const random = THREE.MathUtils.randFloat(0, maxRandomDelay)

    if (random === 0) {

      this.state = state

    } else {

      gsap.to({}, {
        duration: random,
        callbackScope: this,
        onComplete: () => {

          this.state = state
          console.log('state random', state, this.uuid, random)

        }
      })

    }



  }

  /**
   * Zistenie ci je aktualne dany stav
   * @param state -stav
   * @returns True, ak ano
   */
  public isState(state: PlayerStates): boolean {

    return this.state === state

  }

  /**
   * Zistenie ci je aktualny jeden z danych stavov
   * @param states - mozne stavy
   * @returns True, ak ano
   */
  public isOneOfStates(states: PlayerStates[]): boolean {

    return states.includes(this.state)

  }

  /**
   * Getter pre aktualne percento na drahe
   * @returns - actualPercent
   */
  public getActualPercentOnPath(): number {

    return this.worldEnvLinesManager.getActualPercent()

  }

  /**
   * Vratenie objektu atleta
   * @returns Objekt atleta
   */
  protected getObject(): THREE.Object3D {

    // mechanika bude v zdedenej classe
    return new THREE.Object3D

  }

  /**
   * Vytvorenie lyziara
   * @param trackIndex - Index drahy
   * @param athleteName - Meno alteta
   * @param nameForAnimations - Meno pre ziskanie animacii
   */
  public create(trackIndex: number, athleteName: string, nameForAnimations: string): void {

    this.playable = playersManager.getPlayerById(this.uuid)?.playable || false
    this.athleteObject = this.getObject()
    game.scene.add(this.athleteObject)

    // animacie
    this.animationsManager = new AnimationsManager(
      this.athleteObject,
      animationsConfig,
      game.animations.get(nameForAnimations),
      gameConfig.defaultAnimationSpeed,
      fpsManager
    )
    this.animationsManager.setDefaultSpeed(gameConfig.defaultAnimationSpeed)
    this.animationsManager.resetSpeed()

    // toto len aby fungovali uvodne nastavovacky, aj tak sa to potom poposuva
    const position = gameConfig.startPosition

    // threeJS Section
    this.athleteObject.position.set(position.x, position.y, position.z)
    this.athleteObject.name = athleteName

    console.log('atlet vytvoreny pre trat: ', trackIndex)

    this.worldEnvLinesManager = new WorldEnvLinesCreator(
      trackIndex,
      () => {

        this.startLunge()

      },
      (actualPos: number, lastPos: number, finishPos: number) => {

        this.finishReached(actualPos, lastPos, finishPos)

      },
      () => {

        this.setState(PlayerStates.endPath)

      },
      this
    )

    // nastavime vlasy
    this.setHair(trackIndex)

    // nastavime lunge stay framey
    this.setLungeFramesStay()

  }

  /**
   * Nastavenie poctu frameov pre lunge stay
   */
  private setLungeFramesStay(): void {

    this.lungeFramesStay = lungeConfig.frames[LungeStates.stay]
    const attribute = playersManager.getPlayerById(this.uuid)?.attribute.total ?? 0
    if (attribute > 100) this.lungeFramesStay -= 1
    if (attribute > 300) this.lungeFramesStay -= 1
    if (attribute > 600) this.lungeFramesStay -= 1
    if (attribute > 1000) this.lungeFramesStay -= 1

    console.log(`Atlet ${this.uuid} ma ${this.lungeFramesStay} frameov pre lunge stay, atribut bol ${attribute}`)

  }

  /**
   * Nastavenie vlasov a klobuku
   * @param trackIndex - Index drahy
   */
  private setHair(trackIndex: number): void {

    const athlete = playersManager.getPlayerById(this.uuid)
    if (!athlete) return

    const sexWord = athlete.sex === PlayerSex.male ? 'm' : 'f'
    const maxCountHairTypes = sexWord === 'f' ? 7 : 8
    let hairType = athlete.hair ?? Math.floor(Math.random() * maxCountHairTypes) + 1
    if (hairType > maxCountHairTypes) hairType = maxCountHairTypes

    // stipec ma iba typ vlasov 5 pri zene
    const hairClip = this.athleteObject.getObjectByName('stipec_low') as THREE.Mesh
    if (hairClip) {

      hairClip.visible = (hairType === 5 && sexWord === 'f')
      const material = hairClip.material as THREE.MeshBasicMaterial
      if (material) material.color = new THREE.Color(0x783830).convertSRGBToLinear()

    }

    for (let j = 1; j <= 7; j += 1) {

      const meshHair = this.athleteObject.getObjectByName(`${j + sexWord}_low`) as THREE.Mesh
      if (meshHair) {

        meshHair.visible = j === hairType
        // ked toto nastavime, nebudu miznut vlasy
        meshHair.frustumCulled = false

        if (j === hairType) {

          const material = game.materialsToUse.basic.get(`athleteHairTrack${trackIndex}`) as THREE.MeshBasicMaterial
          const hairColor = athlete.hairColor ?? HairColorTypes.brown
          material.color = new THREE.Color(gameConfig.hairColors[HairColorTypes[hairColor]]).convertSRGBToLinear()
          meshHair.material = material

          // kvoli roznym pohlaviam musime davat rozne textury podla pohlavia
          const texture = game.texturesToUse.main.get(`${athlete.sex}/athlete_hair`)
          if (texture) material.map = texture

        }

      }

    }

  }

  /**
   * Odstartovanie hraca
   */
  public start(): void {

    console.log('odstartoval atlet', this.uuid)
    this.setState(PlayerStates.setToStartRun)
    this.speedManager.setActive(true)
    // dame rovno prvy input
    this.velocityManager.handleInputs()
    if (this.playable) store.commit('GamePhaseState/SET_SHOW', true)
    this.updateFramesAfterStart = true
    this.framesAfterStart = 0

  }

  /**
   * Spomalovanie pri false starte
   */
  public falseStartSlowDown(): void {

    if (this.playable) store.commit('GamePhaseState/SET_SHOW', false)
    this.setState(PlayerStates.falseStartSlowDown)
    this.velocityManager.inputsBlocked = true
    this.speedOnStartFalseStart = this.speedManager.getActualSpeed()
    this.speedManager.setSpeedFalseStart()
    this.changeAnimationSpeedFalseStart = true

  }

  /**
   * Aktualizovanie pozicie lyziara
   * @param positionObj - Pozicia
   * @param actualPhase - aktualna faza
   */
  private updatePlayerPosition(positionObj: THREE.Object3D, actualPhase: DisciplinePhases): void {

    this.athleteObject.position.copy(positionObj.position)

    const instalerp = [DisciplinePhases.start, DisciplinePhases.preStart].includes(actualPhase)

    let t = 0.5
    if (instalerp) t = 1

    this.athleteObject.quaternion.slerp(positionObj.quaternion, t)

  }

  /**
   * Vratenie rotacie lyziara
   * @returns Quaternion lyziara
   */
  public getQuaternion(): THREE.Quaternion {

    return this.athleteObject.quaternion

  }

  /**
   * Vratenie pozicie atleta
   * @returns Pozicia atleta
   */
  public getPosition(): THREE.Vector3 {

    return this.athleteObject.position

  }

  /**
   * Aktualizovanie hraca po vykonani fyziky
   */
  public updateAfterPhysics(): void {

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.end) return

    if (!this.speedLockActive) {

      // velocity manager sa riesi iba ked je aktivna rychlost, inak je to neziaduce
      if (this.speedManager.isActive()) this.velocityManager.update()

      this.speedManager.update(
        this.velocityManager.getSpeedPower(),
        this.velocityManager.getMinIdealValue(),
        playersManager.getPlayerById(this.uuid)?.attribute.total || 0
      )

    }
    this.manageLunge()

    // kontrola a spustenie stavu konca
    this.manageEnd()

    this.athleteAnimationManager.update()

    if (gameConfig.debugShowConsoleMetersInFrame && this.updateFramesAfterStart) {

      this.framesAfterStart += 1

      if (this.framesAfterStart % 10 === 0) {

        const frame = this.framesAfterStart
        const percent = this.worldEnvLinesManager.getActualPercent()
        const percentStart = pathsConfig.positionsStart[this.worldEnvLinesManager.pathIndex - 1]
        const meters = (percent - percentStart) * this.worldEnvLinesManager.totalPathLength
        console.log(`atlet ${this.uuid} presiel vo frame ${frame} tolkoto metrov: ${meters}`)

      }

    }


  }

  /**
   * Kontrola, ci nema nastat speed lock
   */
  protected checkSpeedLock(): void {

    if (
      !this.worldEnvLinesManager.isLungeEnabled() ||
      this.speedLockActive ||
      !lungeConfig.speedLockEnabled ||
      disciplinePhasesManager.actualPhase === DisciplinePhases.finish
    ) return

    if (this.playable) {

      disciplinePhasesManager.phaseRunning.stopCollectingQualityRun()
      trainingTasks.saveRunQuality(disciplinePhasesManager.phaseRunning.getQualityRun())

    }
    this.activateSpeedLock()

  }

  /**
   * Aktualizovanie hraca pred vykonanim fyziky
   * @param actualPhase - Aktualna faza
   * @param inTop3 - Ci je hrac v top 3
   */
  public updateBeforePhysics(actualPhase: DisciplinePhases, inTop3 = false): void {

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.end) return

    this.checkSpeedLock()

    const point = this.worldEnvLinesManager.update(
      this.speedManager,
      this.isState(PlayerStates.end) || this.isState(PlayerStates.endPhaseTwo),
      inTop3
    )
    this.updatePlayerPosition(point, actualPhase)

    this.manageFalseStart()

  }

  /**
   * Aktivacia speed lock-u
   */
  public activateSpeedLock(): void {

    console.warn('SPEED LOCK', this.uuid)
    this.speedLockActive = true

  }

  /**
   * Deaktivovanie spomalenia
   */
  public deactivateSpeedLock(): void {

    console.warn('SPEED LOCK end', this.uuid)
    this.speedLockActive = false

  }

  /**
   * Sprava veci pre false start
   */
  private manageFalseStart(): void {

    if (this.changeAnimationSpeedFalseStart) {

      const { animationMinSpeed, minSpeed } = startConfig.falseStart
      const actualSpeed = this.speedManager.getActualSpeed()
      const maxSpeed = this.speedOnStartFalseStart
      const percent = (actualSpeed - minSpeed) / (maxSpeed - minSpeed)
      const speed = animationMinSpeed + ((1 - animationMinSpeed) * percent)
      this.animationsManager.setSpeed(speed)

    }

  }

  /**
   * Sprava konca behu v cieli
   */
  private manageEnd(): void {

    if (this.framesToWaitForEndState > 0) {

      this.framesToWaitForEndState -= 1
      if (this.framesToWaitForEndState === 0) {

        this.setEndState()

      }

    }

  }

  /**
   * Aktualizovanie animacii hraca
   * @param delta - Delta
   */
  public updateAnimations(delta: number): void {

    this.animationsManager.update(delta)

  }

  /**
   * Vypocet attrStrength
   * @returns attrStrength - number
   */
  public getAthleteAttributeStrength(): number {

    const totalStrength = playersManager.getPlayerById(this.uuid)?.attribute.total || 0

    let attrStrength = totalStrength / 4000 + 0.75
    if (totalStrength >= 1000) {

      attrStrength = (totalStrength - 1000) / 5000 + 1

    }

    return attrStrength

  }

  /**
   * Ziskanie poctu max frameov pre lunge
   * @returns lungeMaxFrames
   */
  public getLungeMaxFrames(): number {

    return this.lungeState === LungeStates.stay ? this.lungeFramesStay : lungeConfig.frames[this.lungeState]

  }

  /**
   * Zapnutie lunge-u
   */
  public startLunge(): void {

    // aby sa nespustalo viackrat a aby to bolo v zone, kedy to je mozne dat
    if (this.lungeState !== LungeStates.none || !this.worldEnvLinesManager.isLungeEnabled()) return

    this.setState(PlayerStates.lunge)
    this.lungeState = LungeStates.forward
    this.lungeActualFrame = 0
    this.tutorialAutoLunge = false

  }

  /**
   * Nastavenie lunge offsetu, v % trate
   */
  public setLungeOffset(): void {

    if (!this.isState(PlayerStates.lunge)) {

      this.worldEnvLinesManager.lungeOffset = 0
      return

    }

    // ak sme v stay stave, tak mame max
    let offsetPercent = 1

    if (this.lungeState === LungeStates.forward) {

      offsetPercent = this.lungeActualFrame / this.getLungeMaxFrames()

    }
    if (this.lungeState === LungeStates.backward) {

      offsetPercent = 1 - (this.lungeActualFrame / this.getLungeMaxFrames())

    }

    this.worldEnvLinesManager.lungeOffset = offsetPercent * lungeConfig.lungeOffsetMax *
      this.worldEnvLinesManager.oneMeterInPercent

  }

  /**
   * Managovanie lunge-u
   */
  private manageLunge(): void {

    if (!this.isState(PlayerStates.lunge) || this.lungeState === LungeStates.none) return

    this.lungeActualFrame += 1

    if (this.lungeActualFrame > this.getLungeMaxFrames()) {

      // ak uz sme skoncili backward cast, tak davame naspat run state
      if (this.lungeState === LungeStates.backward) {

        // staci dat run, lebo cielovy stav sa dava az potom, co dobehne urcite lunge
        this.setState(PlayerStates.run)
        return

      }

      if (gameConfig.autoMove.isEnabled && this.playable && this.finalTime === minigameConfig.dnfValue) return

      this.lungeState += 1 // prepneme na dalsi stav
      this.lungeActualFrame = 0

    }

    this.setLungeOffset()

  }

  /**
   * Zistenie, ci sa ma prehravat breathing koniec (true) alebo walk (false)
   * @returns True, ak ma byt breathing koniec
   */
  private isBreathingEndOnFinish(): boolean {

    return this.randomNumberPhaseTwo === 1

  }

  /**
   * Start fazy 2 v cieli
   */
  private startFinishPhaseTwo(): void {

    this.setState(PlayerStates.endPhaseTwo)

    // nerobime nic, ak ma byt walk koniec
    if (!this.isBreathingEndOnFinish()) return

    // spomalime a dame vydychanie
    this.endPhaseTwoFinish = EndTypesFinish.breathing

    this.speedManager.setDataForSpeedOnEnd(
      velocityConfig.speedOnEnd.minSpeed,
      velocityConfig.speedOnEnd.minSpeed / 2,
      velocityConfig.speedOnEnd.framesToStop,
      () => {

        this.speedManager.setDataForSpeedOnEnd(
          velocityConfig.speedOnEnd.minSpeed / 2,
          0,
          1,
          () => {

            // uz nemusime nic robit

          }
        )

      }
    )

  }

  /**
   * Spravanie pri dosiahnuti ciela
   * @param actualPosition - Aktualna pozicia v metroch
   * @param lastPosition - Posledna pozicia v metroch
   * @param finishPosition - Pozicia ciela v metroch
   */
  public finishReached(actualPosition: number, lastPosition: number, finishPosition: number): void {

    this.finished = true

    const finishDist = actualPosition - finishPosition
    const lastFrameDist = actualPosition - lastPosition

    const provisionalTime = timeManager.getGameTimeWithPenaltyInSeconds(false, undefined, 16)
    const offsetTime = (finishDist / lastFrameDist) / 30
    this.finalTime = Math.round(((provisionalTime - offsetTime) * 1000) + Number.EPSILON ) / 1000
    endManager.setPotentialWinnerInFinish(this.uuid, Math.ceil(this.finalTime * 100) / 100)

    this.updateFramesAfterStart = false

    audioHelper.setTimerToChangeAudienceAfterFinish()

    console.log(`urcovanie presneho casu: ${this.uuid}
      finishDist = ${finishDist}
      lastFrameDist = ${lastFrameDist}
      provisionalTime = ${provisionalTime}
      offsetTime = ${offsetTime}
      finalTime = ${this.finalTime}`)

    // animacie v cieli sa budu riesit az neskor, aby stihol dobehnut lunge
    const { frames } = lungeConfig
    this.framesToWaitForEndState = frames[LungeStates.forward] + this.lungeFramesStay +
            frames[LungeStates.backward] + 2

    // mimo dennej ligy davame vsetkym superom, co este nepresli cielom po hracovi DNF, aby sa dobre vyhodnocovalo
    if (!modes.isDailyLeague() && this.playable) window.dispatchEvent(new CustomEvent(CustomGameEvents.playerFinished))

    if (!modes.isTrainingMode()) return

    const lungeEfficiency = Math.round(100 /
       lungeConfig.lungeOffsetMax * this.worldEnvLinesManager.lungeOffset /
        this.worldEnvLinesManager.oneMeterInPercent) / 100
    trainingTasks.saveTaskValue(Tasks.lunge, lungeEfficiency)

  }

  /**
   * Nastavenie stavu konca
   */
  private setEndState(): void {

    this.setState(PlayerStates.end)

    const resultEmotionWinner = endManager.isWinnerInFinish(this.uuid)
    if (resultEmotionWinner) {

      // davame animaciu happy
      this.emotionFinish = EmotionTypesFinish.winner

    }

    const { minSpeed, minSpeedWalk, framesToMinSpeed, randomizeEnd } = velocityConfig.speedOnEnd
    // musime si uz tu urcit random pre pokracovanie, aby sme vedeli, na aku rychlost spomalit
    this.randomNumberPhaseTwo = this.playable ? 1 : Math.round(Math.random())

    // zacneme spomalovat
    this.speedManager.setDataForSpeedOnEnd(
      this.speedManager.getActualSpeed(),
      this.isBreathingEndOnFinish() ? minSpeed : minSpeedWalk,
      THREE.MathUtils.randInt(framesToMinSpeed - randomizeEnd.minus, framesToMinSpeed + randomizeEnd.plus),
      () => {

        this.startFinishPhaseTwo()

      }
    )

  }

  /**
   * reset atleta
   */
  public reset(): void {

    this.finished = false
    this.state = PlayerStates.prepare
    this.activeUpdatingMovementAnimations = false
    this.lastAthletePosition.set(0, 0, 0)
    this.emotionFinish = EmotionTypesFinish.looser
    this.endPhaseTwoFinish = EndTypesFinish.walk
    this.lungeState = LungeStates.none
    this.lungeActualFrame = 0
    this.randomNumberPhaseTwo = 0
    this.framesToWaitForEndState = 0
    this.changeAnimationSpeedFalseStart = false
    this.speedOnStartFalseStart = 0
    this.updateFramesAfterStart = false
    this.framesAfterStart = 0
    this.finalTime = minigameConfig.dnfValue

    this.velocityManager.reset()
    this.speedManager.reset()
    this.animationsManager.resetAll()
    this.worldEnvLinesManager.reset()
    this.athleteAnimationManager.reset()

  }

}

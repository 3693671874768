/** Nazvy zvukov */
export enum AudioNames {

  spriteCommentators = 'commentators',
  spriteAudienceNotLooped = 'audienceNotLooped',
  spriteAudienceLooped = 'audienceLooped',
  spriteStart = 'start',
  spriteFootsteps = 'footstepsPlayer',

  audienceRace = '100m_hyped_overlay',
  audienceIntro = 'audience-noise',
  audienceFalseStart = 'audience-bad-overlay-3',
  audienceHyped = 'audience-hyped-overlay-5',

  footstepPlayer = 'footstep-player',
  footstepOpponent = 'footstep-opponent',

  falseStartShot = 'pistol-false-start',
  judgeSet = 'judge-set',
  startShot = 'pistol-start',

  commentatorIntro = 'VO_100meters_intro_var01',
  commentatorIntro2 = 'VO_100meters_intro_var012',
  commentatorFinalResults = 'VO_100meters_final_results_var01',
  commentatorFinalResults2 = 'VO_100meters_final_results_var012',
  commentatorFalseStart = 'VO_100meters_false_start',
  commentatorSecondFalseStart = 'VO_100meters_second_false_start',
  commentatorStartPoor = 'VO_100meters_poor_start',
  commentatorStartGood = 'VO_100meters_good_start',
  commentatorStartPerfect = 'VO_100meters_perfect_start_var01',
  commentatorStartPerfect2 = 'VO_100meters_perfect_start_var012',
  commentatorRank123 = 'VO_100meters_rank_1_3_var01',
  commentatorRank123v2 = 'VO_100meters_rank_1_3_var012',
  commentatorRank1 = 'VO_100meters_rank_1_var01',
  commentatorRank1v2 = 'VO_100meters_rank_1_var012',
  commentatorRank23 = 'VO_100meters_rank_2_3',
  commentatorRank45 = 'VO_100meters_rank_4_5',
  commentatorRank6 = 'VO_100meters_rank_6',
  commentatorPersonalBest = 'VO_100meters_personal_best',
  commentatorWorldRecord = 'VO_100meters_world_record'

}

/** Kategorie zvukov */
export enum AudioCategories {

  start = 'start',
  audience = 'audience',
  commentators = 'commentators',
  sprite = 'sprites'

}

/** skupiny zvukov */
export enum AudioGroups {

  commentators = 'commentators',
  audience = 'audience'

}

<template>
  <div
    class="under-menu-component"
    :class="[isMobile() ? 'mobile-under-menu-component' : '']"
    :style="transformCoef"
  >
    <div class="component-item">
      <time-keeper-component />
      <double-box
        v-if="getShowWind"
        :is-scaled="false"
        :left-width="60"
        :right-width="148"
        :left-image="'https://appspowerplaymanager.vshcdn.net/images/summer-sports/minigame/global/ui/ico-wind.png'"
        :right-text="getWindText"
        :side="'right'"
      />
    </div>
  </div>
</template>

<script lang="ts">
// External Package codes
import { mapGetters } from 'vuex'
import {
  WindowAspect,
  DoubleBox
} from '@powerplay/core-minigames-ui-ssm'

// Game Components
import TimeKeeperComponent from '@/components/TimeKeeperComponent/index.vue'

import { defineComponent } from 'vue'
import { wind } from '@/app/entities/athlete/Wind'

export default defineComponent({
  name: 'UnderMenuComponent',
  components: {
    TimeKeeperComponent,
    DoubleBox
  },
  mixins: [WindowAspect],
  computed: {
    ...mapGetters({
      getShowWind: 'UiState/getShowWind'
    }),
    transformCoef() {

      return {
        transform: `scale(${this.scaleCoef})`,
        'transform-origin': '100% 0'
      }

    },
    getWindText() {

      return `${wind.getValueWithSignAsString()} m/s`

    }
  },
})

</script>

<style lang="less" scoped>

.under-menu-component{
    position: absolute;
    right: 1%;
    top: 14%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &.mobile-under-menu-component {
        top: 14%;
    }

    .component-item {
        position: relative;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 20px;
    }

}

</style>

import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface BlackOverlayState {
  showBlackScreen: boolean
}

const initialState = () => ({
  showBlackScreen: false
})

const blurState: Module<BlackOverlayState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getShowOverlay: (moduleState: BlackOverlayState) => moduleState.showBlackScreen
  },

  mutations: {
    RESET: (moduleState: BlackOverlayState) => {

      Object.assign(moduleState, initialState())

    },
    SET_BLACK_SCREEN: (moduleState: BlackOverlayState, newState: boolean) => {

      moduleState.showBlackScreen = newState

    }
  }
}

export default blurState

import {
  type MaterialDataObject,
  THREE
} from '@powerplay/core-minigames'
import {
  MaterialsNames,
  TexturesNames
} from '../types'

/**
 * Konfig pre materialy
 */
export const materialsConfig: MaterialDataObject = {

  [MaterialsNames.athlete]: {
    meshesArray: [
      'body_low', 'f_body_low'
    ],
    textureName: TexturesNames.athleteClothes,
    playerIndex: 0
  },
  [MaterialsNames.athleteOpponent1]: {
    meshesArray: [
      'body_low_opponent_1', 'f_body_low_opponent_1'
    ],
    textureName: TexturesNames.athleteClothesOpponent1,
    playerIndex: 1

  },
  [MaterialsNames.athleteOpponent2]: {
    meshesArray: [
      'body_low_opponent_2', 'f_body_low_opponent_2'
    ],
    textureName: TexturesNames.athleteClothesOpponent2,
    playerIndex: 2

  },
  [MaterialsNames.athleteOpponent3]: {
    meshesArray: [
      'body_low_opponent_3', 'f_body_low_opponent_3'
    ],
    textureName: TexturesNames.athleteClothesOpponent3,
    playerIndex: 3

  },
  [MaterialsNames.athleteOpponent4]: {
    meshesArray: [
      'body_low_opponent_4', 'f_body_low_opponent_4'
    ],
    textureName: TexturesNames.athleteClothesOpponent4,
    playerIndex: 4

  },
  [MaterialsNames.athleteOpponent5]: {
    meshesArray: [
      'body_low_opponent_5', 'f_body_low_opponent_5'
    ],
    textureName: TexturesNames.athleteClothesOpponent5,
    playerIndex: 5

  },
  [MaterialsNames.athleteOpponent6]: {
    meshesArray: [
      'body_low_opponent_6', 'f_body_low_opponent_6'
    ],
    textureName: TexturesNames.athleteClothesOpponent6,
    playerIndex: 6

  },
  [MaterialsNames.athleteOpponent7]: {
    meshesArray: [
      'body_low_opponent_7', 'f_body_low_opponent_7'
    ],
    textureName: TexturesNames.athleteClothesOpponent7,
    playerIndex: 7

  },
  [MaterialsNames.athleteHairTrack1]: {
    meshesArray: [],
    transparent: true, // zatial davame vsetky transparentne
  },
  [MaterialsNames.athleteHairTrack2]: {
    meshesArray: [],
    transparent: true, // zatial davame vsetky transparentne
  },
  [MaterialsNames.athleteHairTrack3]: {
    meshesArray: [],
    transparent: true, // zatial davame vsetky transparentne
  },
  [MaterialsNames.athleteHairTrack4]: {
    meshesArray: [],
    transparent: true, // zatial davame vsetky transparentne
  },
  [MaterialsNames.athleteHairTrack5]: {
    meshesArray: [],
    transparent: true, // zatial davame vsetky transparentne
  },
  [MaterialsNames.athleteHairTrack6]: {
    meshesArray: [],
    transparent: true, // zatial davame vsetky transparentne
  },
  [MaterialsNames.athleteHairTrack7]: {
    meshesArray: [],
    transparent: true, // zatial davame vsetky transparentne
  },
  [MaterialsNames.athleteHairTrack8]: {
    meshesArray: [],
    transparent: true, // zatial davame vsetky transparentne
  },
  [MaterialsNames.athleteHairClip]: {
    meshesArray: ['stipec_low'],
  },
  [MaterialsNames.audience]: {
    textureName: TexturesNames.audience,
    meshesArray: ['Stadium2_Collapsed_Mesh_10'],
    lightmap: TexturesNames.envLightmapDark,
    alpha: 0.5, // alphatest
    transparent: true,
    vertexColors: true,
  },
  [MaterialsNames.audienceFlags]: {
    textureName: TexturesNames.flagsImages,
    meshesArray: ['AthleticStadium_Stadium2_Flags'],
    lightmap: TexturesNames.envLightmapDark,
    vertexColors: true,
  },
  [MaterialsNames.colorGradient]: {
    textureName: TexturesNames.colorGradient,
    meshesArray: [
      'envDynamic_Stadium2_Ads100m', 'envDynamic_Stadium2_Screen1', 'envDynamic_Stadium2_Screen2',
      'Stadium2_Collapsed_Mesh_4', 'Stadium2_100m_Mesh_1'
    ],
  },
  [MaterialsNames.concrete]: {
    textureName: TexturesNames.concrete,
    meshesArray: ['Stadium2_Collapsed_Mesh_5', 'Stadium2_Collapsed_Mesh_7', 'Stadium2_Collapsed_Mesh_13'],
    lightmap: TexturesNames.envLightmapDark,
    vertexColors: true,
  },
  [MaterialsNames.glassPanel]: {
    textureName: TexturesNames.glassPanel,
    meshesArray: ['Stadium2_Collapsed_Mesh_1'],
    lightmap: TexturesNames.envLightmapDark,
    transparent: true,
    vertexColors: true,
  },
  [MaterialsNames.grass]: {
    textureName: TexturesNames.grass,
    meshesArray: ['Stadium2_Collapsed_Mesh_8'],
    lightmap: TexturesNames.envLightmapDark,
    vertexColors: true,
  },
  [MaterialsNames.hexCladding]: {
    textureName: TexturesNames.hexCladding,
    meshesArray: ['Stadium2_Collapsed_Mesh'],
    lightmap: TexturesNames.envLightmapDark,
    vertexColors: true,
  },
  [MaterialsNames.metal]: {
    textureName: TexturesNames.metal,
    meshesArray: ['Stadium2_Collapsed_Mesh_3', 'Stadium2_100m_Mesh'],
    lightmap: TexturesNames.envLightmapDark,
    vertexColors: true,
  },
  [MaterialsNames.panel]: {
    textureName: TexturesNames.panel,
    meshesArray: ['Stadium2_Collapsed_Mesh_2'],
    lightmap: TexturesNames.envLightmapDark,
    vertexColors: true,
  },
  [MaterialsNames.camera]: {
    textureName: TexturesNames.camera,
    meshesArray: ['envDynamic_Stadium2_Camera'],
  },
  [MaterialsNames.sand]: {
    textureName: TexturesNames.sand,
    meshesArray: ['Stadium2_Collapsed_Mesh_9'],
    lightmap: TexturesNames.envLightmapDark,
    vertexColors: true,
  },
  [MaterialsNames.seats]: {
    textureName: TexturesNames.seats,
    meshesArray: ['Stadium2_Collapsed_Mesh_12'],
    lightmap: TexturesNames.envLightmapDark,
    vertexColors: true,
    alpha: 0.5, // alphatest
  },
  [MaterialsNames.stairs]: {
    textureName: TexturesNames.stairs,
    meshesArray: ['Stadium2_Collapsed_Mesh_6'],
    lightmap: TexturesNames.envLightmapDark,
    vertexColors: true,
  },
  [MaterialsNames.npc]: {
    textureName: TexturesNames.npc,
    meshesArray: ['Stadium2_100m_Mesh_2'],
    lightmap: TexturesNames.envLightmapDark,
    vertexColors: true,
  },
  [MaterialsNames.texts]: {
    textureName: TexturesNames.trackTexts,
    meshesArray: ['envDynamic_StartFlags_100m'],
    opacity: 0.8,
    transparent: true
  },
  [MaterialsNames.texts2]: {
    textureName: TexturesNames.trackTexts,
    meshesArray: ['envDynamic_LeadIndicator_Text'/* , 'PlayerIndicator_Text'*/],
    opacity: 0.8,
    transparent: true
  },
  [MaterialsNames.lattice]: {
    textureName: TexturesNames.lattice,
    meshesArray: ['Stadium2_Collapsed_Mesh_11'],
    lightmap: TexturesNames.envLightmapDark,
    alpha: 0.5,
    vertexColors: true,
  },
  [MaterialsNames.emissive]: {
    textureName: TexturesNames.emissive,
    meshesArray: ['envDynamic_Stadium2_Emissive'],
    alpha: 0.5, // alphatest
  },
  [MaterialsNames.flagsImages1]: {
    textureName: TexturesNames.flagsImages,
    alpha: 0.5,
    meshesArray: ['envDynamic_StartFlag_1'],
  },
  [MaterialsNames.flagsImages2]: {
    textureName: TexturesNames.flagsImages,
    alpha: 0.5,
    meshesArray: ['envDynamic_StartFlag_2'],
  },
  [MaterialsNames.flagsImages3]: {
    textureName: TexturesNames.flagsImages,
    alpha: 0.5,
    meshesArray: ['envDynamic_StartFlag_3'],
  },
  [MaterialsNames.flagsImages4]: {
    textureName: TexturesNames.flagsImages,
    alpha: 0.5,
    meshesArray: ['envDynamic_StartFlag_4'],
  },
  [MaterialsNames.flagsImages5]: {
    textureName: TexturesNames.flagsImages,
    alpha: 0.5,
    meshesArray: ['envDynamic_StartFlag_5'],
  },
  [MaterialsNames.flagsImages6]: {
    textureName: TexturesNames.flagsImages,
    alpha: 0.5,
    meshesArray: ['envDynamic_StartFlag_6'],
  },
  [MaterialsNames.flagsImages7]: {
    textureName: TexturesNames.flagsImages,
    alpha: 0.5,
    meshesArray: ['envDynamic_StartFlag_7'],
  },
  [MaterialsNames.flagsImages8]: {
    textureName: TexturesNames.flagsImages,
    alpha: 0.5,
    meshesArray: ['envDynamic_StartFlag_8'],
  },
  [MaterialsNames.flagsTexts]: {
    textureName: TexturesNames.alphabet,
    alpha: 0.5,
    meshesArray: [
      'envDynamic_StartText_1_1', 'envDynamic_StartText_1_2', 'envDynamic_StartText_1_3',
      'envDynamic_StartText_2_1', 'envDynamic_StartText_2_2', 'envDynamic_StartText_2_3',
      'envDynamic_StartText_3_1', 'envDynamic_StartText_3_2', 'envDynamic_StartText_3_3',
      'envDynamic_StartText_4_1', 'envDynamic_StartText_4_2', 'envDynamic_StartText_4_3',
      'envDynamic_StartText_5_1', 'envDynamic_StartText_5_2', 'envDynamic_StartText_5_3',
      'envDynamic_StartText_6_1', 'envDynamic_StartText_6_2', 'envDynamic_StartText_6_3',
      'envDynamic_StartText_7_1', 'envDynamic_StartText_7_2', 'envDynamic_StartText_7_3',
      'envDynamic_StartText_8_1', 'envDynamic_StartText_8_2', 'envDynamic_StartText_8_3',
    ],
  },
  [MaterialsNames.flagsGradient]: {
    textureName: TexturesNames.gradient,
    vertexColors: true,
    transparent: true,
    meshesArray: [
      'envDynamic_StartFlagsBG_1', 'envDynamic_StartFlagsBG_2', 'envDynamic_StartFlagsBG_3',
      'envDynamic_StartFlagsBG_4', 'envDynamic_StartFlagsBG_5', 'envDynamic_StartFlagsBG_6',
      'envDynamic_StartFlagsBG_7', 'envDynamic_StartFlagsBG_8'
    ],
  },
  [MaterialsNames.leadIndicator]: {
    textureName: TexturesNames.gradient,
    transparent: true,
    meshesArray: ['envDynamic_LeadIndicator_BG', 'envDynamic_LeadIndicator_Line', 'envDynamic_PlayerIndicator_BG'],
  },
  [MaterialsNames.numbers]: {
    textureName: TexturesNames.numbers,
    meshesArray: ['Stadium2_100m_Mesh_4'],
    lightmap: TexturesNames.envLightmapDark,
  },
  [MaterialsNames.lightFlashes]: {
    textureName: TexturesNames.lightFlashes,
    transparent: true,
    blending: THREE.AdditiveBlending,
    depthWrite: false,
    depthTest: false,
    meshesArray: ['envDynamic_LightFlash'],
  },
  [MaterialsNames.lightsGradient]: {
    textureName: TexturesNames.gradient,
    transparent: true,
    vertexColors: true,
    blending: THREE.AdditiveBlending,
    depthWrite: false,
    depthTest: false,
    meshesArray: ['envDynamic_Stadium2_Lightshafts'],
  },
  [MaterialsNames.skybox]: {
    textureName: TexturesNames.skybox,
    meshesArray: ['AthleticStadium_Stadium2_Sky'],
  },
  [MaterialsNames.onlyVertexColors]: {
    isDefault: true,
    meshesArray: [],
    vertexColors: true,
    lightmap: TexturesNames.envLightmapDark,
  }

}

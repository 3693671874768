<template>
  <time-keeper
    v-if="uiState.showTimeKeeper"
    class="time-keeper"
    :text1="timeState.time"
    :is-scaled="false"
  />
</template>

<script lang="ts">
import { TimeKeeper } from '@powerplay/core-minigames-ui-ssm'
import { mapGetters } from 'vuex'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TimeKeeperComponent',
  components: {
    TimeKeeper
  },
  computed: {
    ...mapGetters({
      timeState: 'TimeState/getTimeState',
      uiState: 'UiState/getUiState'
    })
  }

})

</script>

<style lang="less">
.component-item {

    .time-keeper {
        position: initial;
        height: auto;
    }
}
</style>

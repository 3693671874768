import { flagsConfig } from '@/app/config/flagsConfig'
import {
  game,
  modes,
  playersManager,
  THREE
} from '@powerplay/core-minigames'
import { player } from '../athlete/player'
import { opponentsManager } from '../athlete/opponent/OpponentsManager'
import {
  LeaderColors,
  PlayerStates,
  TexturesNames
} from '@/app/types'
import { materialsConfig } from '@/app/config/materialsConfig'
import { endManager } from '@/app/EndManager'
import {
  cameraConfig,
  gameConfig
} from '@/app/config'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'

/**
 * Trieda pre prostredie
 */
export class WorldEnv {

  /** Mesh pre ciaru pre lidra */
  private lineLeader!: THREE.Mesh

  /** Mesh pre gradient pod text pre lidra */
  private textGradientLeader!: THREE.Mesh

  /** Mesh pre text pre lidra */
  private textLeader!: THREE.Mesh

  /** Mesh pre gradient pod text pre hraca na startovacej drahe */
  private textGradientPlayer!: THREE.Mesh

  /** Mesh pre napis 100 meters */
  private textMesh100m!: THREE.Mesh

  /** Nesh pre pohyblivu kameru */
  private movableCamera!: THREE.Mesh

  /** Textura pre zablesky fotakov */
  private cameraFlashesTexture?: THREE.Texture | null

  /** Offset pre texturu pre zablesky fotakov */
  private cameraFlashesOffset = new THREE.Vector2()

  /** Pocitadlo frameov */
  private frameCounter = 0

  /** pozicia hraca na x osi - kvoli tutorialu, kde sa to meni po cieli */
  private playerPositionX = -99999

  /**
   * Vytvorenie prostredia
   */
  public create(): void {

    console.log('VYTVARAM ENV....')
    this.setVisibilityGroundFlags(false)
    this.createLineLeader()
    this.createCameraFlashes()
    this.createMovableCamera()
    this.createTextPlayer()

    this.textMesh100m = game.getObject3D('envDynamic_StartFlags_100m') as THREE.Mesh
    console.log('ENV vytvoreny....')

  }

  /**
   * Vytvorenie grafiky pre hraca, v ktorej drahe zacina
   */
  private createTextPlayer(): void {

    this.textGradientPlayer = game.getMesh('envDynamic_PlayerIndicator_BG')
    this.setVisibilityTextPlayer(false)

  }

  /**
   * Nastavenie viditelnosti textu pre hraca, kvoli startovacej drahe
   * @param visibility - Ci ma byt vidiet
   * @param reversed - Ci ma byt od konca
   */
  public setVisibilityTextPlayer(visibility: boolean, reversed = false): void {

    // rozne veci menime iba, ked sa zviditelnuje
    if (visibility) {

      let positionZ = 57.6
      let rotationY = 0
      if (reversed) {

        positionZ = -41.7
        rotationY = Math.PI

      }

      // nastavime farbu pre gradient pre hraca
      const leaderMaterial = this.textGradientPlayer.material as THREE.MeshBasicMaterial
      leaderMaterial.color.set(gameConfig.colorsLeader[LeaderColors.lightBlue])

      this.textGradientPlayer.position.set(this.playerPositionX, 0.01, positionZ)
      this.textGradientPlayer.rotation.y = rotationY
      this.textGradientPlayer.updateMatrix()

    }

    this.textGradientPlayer.visible = visibility

  }

  /**
   * Nastavenie hracovej pozicie na osi x, hlavne v tutoriali, kde po prejdeni ciela to blbne
   */
  public setPlayerPositionX(): void {

    // nastavujeme ale iba vtedy, ak to este nie je nastavene, aby bola spravna hodnota
    if (this.playerPositionX === -99999) this.playerPositionX = player.getPosition().x

  }

  /**
   * Vytvorenie ciary pre lidra
   */
  private createLineLeader(): void {

    this.lineLeader = game.getMesh('envDynamic_LeadIndicator_Line')
    this.lineLeader.position.y = 0.008
    this.lineLeader.position.z = 37.1
    this.lineLeader.matrixAutoUpdate = true
    this.textGradientLeader = game.getMesh('envDynamic_LeadIndicator_BG')
    this.textGradientLeader.matrixAutoUpdate = true
    this.textLeader = game.getMesh('envDynamic_LeadIndicator_Text')
    this.textLeader.matrixAutoUpdate = true
    this.textLeader.renderOrder = 1

    this.setVisibilityLineLeader(false)

  }

  /**
   * Vytvorenie zableskov fotakov
   */
  private createCameraFlashes(): void {

    const mesh = game.getMesh('envDynamic_LightFlash')
    if (!mesh) return
    const material = mesh.material as THREE.MeshBasicMaterial
    this.cameraFlashesTexture = material?.map
    const map = material.map
    if (map) map.minFilter = THREE.NearestFilter

  }

  /**
   * Spravovanie zableskov fotakov
   */
  public manageCameraFlashes(): void {

    this.frameCounter += 1

    const { everyXframe, offsetShift } = cameraConfig.flashes

    if (!this.cameraFlashesTexture || this.frameCounter % everyXframe !== 0) return

    this.cameraFlashesOffset.x += THREE.MathUtils.randFloat(offsetShift.min, offsetShift.max)
    if (this.cameraFlashesOffset.x > 1) this.cameraFlashesOffset.x = 0

    this.cameraFlashesTexture.offset.copy(this.cameraFlashesOffset)
    this.cameraFlashesTexture.needsUpdate = true

  }

  /**
   * Vytvorenie pohyblivej kamery
   */
  private createMovableCamera(): void {

    this.movableCamera = game.getObject3D('envDynamic_Stadium2_Camera') as THREE.Mesh
    this.movableCamera.visible = false
    this.movableCamera.matrixAutoUpdate = true
    this.movableCamera.position.set(48.527713775634766, 0.13994646072387695, 58.090593444824215)

  }

  /**
   * Nastavenie viditelnosti pre ciaru pre lidra
   * @param visibility - Viditelnost
   */
  public setVisibilityLineLeader(visibility: boolean): void {

    this.lineLeader.visible = visibility
    this.textGradientLeader.visible = visibility
    this.textLeader.visible = visibility

  }

  /**
   * Kontrolovanie veci pre lidra
   */
  public manageLeader(): void {

    const notPossibleStates = [
      PlayerStates.prepare,
      PlayerStates.prepare2,
      PlayerStates.prepareSpecial,
      PlayerStates.onYourMarks,
    ]


    // podla z pozicie, nie percenta, lebo ciary su rozne dlhe
    const playerZ = player.athleteObject.position.z
    let bestZ = 9999
    let bestX = 0
    opponentsManager.getOpponents().forEach((opponent) => {

      const z = opponent.athleteObject.position.z
      if (z < bestZ) {

        bestZ = opponent.athleteObject.position.z
        bestX = opponent.athleteObject.position.x

      }

    })

    let color: LeaderColors = LeaderColors.lightBlue

    // ak bol skor hrac, tak robime nejake veci
    if (playerZ <= bestZ) {

      bestZ = playerZ
      bestX = player.athleteObject.position.x
      if (player.speedManager.isActive()) {

        color = LeaderColors.green

      }

    }

    // pohneme kameru
    this.movableCamera.position.z = bestZ

    if (
      player.speedLockActive ||
      player.isOneOfStates(notPossibleStates) ||
      endManager.isSomeoneInFinish() ||
      modes.isTrainingMode() ||
      disciplinePhasesManager.phaseStart.wasFalseStart
    ) {

      this.setVisibilityLineLeader(false)
      return

    }

    // pohneme vsetkou grafikou
    this.lineLeader.position.z = bestZ
    this.textLeader.position.set(bestX, 0.01, bestZ)
    this.textGradientLeader.position.set(bestX, 0.008, bestZ)

    // nastavime farbu pre lidra
    const leaderMaterial = this.lineLeader.material as THREE.MeshBasicMaterial
    leaderMaterial.color.set(gameConfig.colorsLeader[color])

  }

  /**
   * Nastavenie viditelnosti pre text 100 meters
   * @param visibility - Viditelnost
   */
  public setVisibilityText100m(visibility: boolean): void {

    this.textMesh100m.visible = visibility

  }

  /**
   * Zmena UV
   * @param mesh - Mesh na ktorom menime UVcka
   * @param indexU - index pre u koordinat
   * @param indexV - index pre v koordinat
   * @param horizontal - horizontalna hodnota posunu pre offset
   * @param vertical - vertikalna hodnota posunu pre offset
   */
  private changeUVs(mesh: THREE.Mesh, indexU: number, indexV: number, horizontal: number, vertical: number): void {

    const uvAttribute = mesh.geometry.attributes.uv
    for (let i = 0; i < uvAttribute.count; i++) {

      const u = uvAttribute.getX(i) + (horizontal * indexU)
      const v = uvAttribute.getY(i) + (vertical * indexV)

      uvAttribute.setXY(i, u, v)

    }

    uvAttribute.needsUpdate = true

  }

  /**
   * Nastavenie pismena
   * @param letter - Pismeno
   * @param mesh - Mesh pre pismeno
   * @param horizontalValue - horizontalna hodnota posunu pre offset
   * @param verticalValue - vertikalna hodnota posunu pre offset
   */
  private setLetter(letter: string, mesh: THREE.Mesh, horizontalValue: number, verticalValue: number): void {

    const alphabetIndexes = flagsConfig.alphabet.indexes as {[key in string]: number[]}
    const indexes: number[] = alphabetIndexes[letter]
    if (indexes) {

      this.changeUVs(mesh, indexes[0], indexes[1], horizontalValue, verticalValue)
      mesh.visible = true

    }


  }

  /**
   * Vymena vlajky a textu na objekte
   * @param index - Index pre mesh
   * @param country - Krajina na zobrazenie
   * @param countryString - Krajina na zobrazenie textu
   */
  private changeFlagOnMesh(index: number, country: string, countryString: string): void {

    const { pathMeshNames, countries, horizontalCount, horizontalValue, verticalValue, alphabet } = flagsConfig

    // potrebujeme mesh pre obrazok vlajky aj pre text
    const mesh = game.scene.getObjectByName(`${pathMeshNames[0]}${index}`) as THREE.Mesh
    const meshText1 = game.scene.getObjectByName(`${pathMeshNames[1]}${index}_1`) as THREE.Mesh
    const meshText2 = game.scene.getObjectByName(`${pathMeshNames[1]}${index}_2`) as THREE.Mesh
    const meshText3 = game.scene.getObjectByName(`${pathMeshNames[1]}${index}_3`) as THREE.Mesh
    const meshBG = game.scene.getObjectByName(`${pathMeshNames[2]}${index}`) as THREE.Mesh
    let countryIndex = countries.indexOf(country.toUpperCase())
    if (countryIndex === -1) countryIndex = 95 // other world

    if (mesh) {

      // zistime si, ake uv nasobice by sme mali davat
      const indexU = countryIndex % horizontalCount
      const indexV = Math.floor(countryIndex / horizontalCount)
      this.changeUVs(mesh, indexU, indexV, horizontalValue, verticalValue)
      mesh.visible = true

    }

    if (meshBG) meshBG.visible = true

    if (meshText1 || meshText2 || meshText3) {

      const countryUC = countryString.toUpperCase()
      this.setLetter(countryUC[0], meshText1, alphabet.horizontalValue, alphabet.verticalValue)
      this.setLetter(countryUC[1], meshText2, alphabet.horizontalValue, alphabet.verticalValue)
      this.setLetter(countryUC[2], meshText3, alphabet.horizontalValue, alphabet.verticalValue)

    }

  }

  /**
   * Zobrazenie vlajok v intre
   */
  public showFlagsIntro(): void {

    if (modes.isTrainingMode()) return

    // najskor hraca
    this.changeFlagOnMesh(
      8 - player.worldEnvLinesManager.pathIndex + 1,
      playersManager.getPlayer().country,
      playersManager.getPlayer().countryString ?? 'unknown'
    )
    // potom superov
    opponentsManager.getOpponents().forEach((opponent) => {

      this.changeFlagOnMesh(
        8 - opponent.worldEnvLinesManager.pathIndex + 1,
        playersManager.getPlayerById(opponent.uuid)?.country ?? 'unknown',
        playersManager.getPlayerById(opponent.uuid)?.countryString ?? 'unknown'
      )

    })

  }

  /**
   * Zobrzenie/schovanie vsetkych vlajok a bg pod nimi
   * @param visibility - Viditelnost vlajok a bg
   */
  public setVisibilityGroundFlags(visibility: boolean): void {

    for (let i = 1; i <= 8; i += 1) {

      flagsConfig.pathMeshNames.forEach((name, index) => {

        if (index === 1) {

          for (let j = 1; j <= 3; j += 1) {

            const mesh = game.scene.getObjectByName(`${name}${i}_${j}`)
            if (mesh) mesh.visible = visibility

          }

        } else {

          const mesh = game.scene.getObjectByName(`${name}${i}`)
          if (mesh) mesh.visible = visibility

        }

      })

    }

  }

  /**
   * Zmena lightmapy z tmavej normalnu
   */
  public changeLightmapFromDarkToNormal(): void {

    for (const [index, materialConfig] of Object.entries(materialsConfig)) {

      if (materialConfig.lightmap !== TexturesNames.envLightmapDark) continue

      // staci nam jeden prvy mesh a ked zmenime na jeho materialy lightmapu, zmeni sa na vsetkych s danym materialom
      const meshName = materialConfig.meshesArray?.[0] ?? ''
      if (meshName === '') continue
      const mesh = game.getObject3D(meshName) as THREE.Mesh
      const material = mesh.material as THREE.MeshBasicMaterial
      const texture = game.texturesToUse.lightmap.get(TexturesNames.envLightmap)
      if (texture) material.lightMap = texture
      console.log(index)

    }

    this.movableCamera.visible = true

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    // nemusime riesit nic zatial

  }

}

export const worldEnv = new WorldEnv()

<template>
  <section
    class="mobile-inputs"
    @touchstart.prevent="touchStart"
    @mousedown.prevent="touchStart"
    @mouseup.prevent="endAction"
    @touchend.prevent="endAction"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { inputsManager } from '@powerplay/core-minigames'

export default defineComponent({
  name: 'MobileInputs',
  components: {
  },
  methods: {
    touchStart() {

      console.warn('TOUCH START')
      disciplinePhasesManager.phaseStart.handleInputs()
      disciplinePhasesManager.phaseRunning.handleInputs()

    },
    endAction() {

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    }
  }
})
</script>

<style lang="less" scoped>
  .mobile-inputs {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    pointer-events: auto;
  }

</style>

import type { AudioObject } from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'
import { audioSprites } from './spriteAudioConfig'
/**
 * Trieda konfigu pre zvuky
 */
export const audioConfig: AudioObject[] = [

  ...audioSprites,

  {
    files: [
      AudioNames.judgeSet
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.start,
    version: 2
  },

  {
    files: [
      AudioNames.startShot
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.start,
    version: 2
  },
  {
    files: [
      AudioNames.audienceIntro
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: true,
    category: AudioCategories.audience,
    group: AudioGroups.audience,
    version: 2
  },
  {
    files: [
      AudioNames.audienceRace
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: true,
    category: AudioCategories.audience,
    group: AudioGroups.audience,
    version: 2
  },
  {
    files: [
      AudioNames.commentatorIntro,
      AudioNames.commentatorIntro2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    loadRandomCount: 1,
    category: AudioCategories.commentators,
    group: AudioGroups.commentators
  },
  {
    files: [
      AudioNames.commentatorFinalResults,
      AudioNames.commentatorFinalResults2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    loadRandomCount: 1,
    category: AudioCategories.commentators,
    group: AudioGroups.commentators
  },

]

import {
  gsap,
  CameraStates,
  corePhasesManager,
  modes,
  cameraManager,
  audioManager,
  timeManager,
  TimesTypes,
  tutorialManager
} from '@powerplay/core-minigames'
import {
  type DisciplinePhaseManager,
  type DisplayMessage,
  TutorialObjectiveIds,
  AudioNames,
  PlayerStates,
  Tasks,
  TutorialEventType
} from '../../types'
import store from '@/store'
import { player } from '../../entities/athlete/player'
import {
  gameConfig,
  startConfig,
  trainingConfig
} from '../../config'
import { startPhaseStateManager } from './StartPhaseStateManager'
import { tutorialObjectives } from '@/app/modes/tutorial/TutorialObjectives'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { tutorialUIChange } from '@/app/modes/tutorial/TutorialUIChange'
import { opponentsManager } from '@/app/entities/athlete/opponent/OpponentsManager'
import { worldEnv } from '@/app/entities/env/WorldEnv'
import { trainingTasks } from '@/app/modes/training'
import { audioHelper } from '@/app/audioHelper/AudioHelper'
import { endManager } from '@/app/EndManager'

/**
 * Trieda pre startovaciu fazu
 */
export class StartPhaseManager implements DisciplinePhaseManager {

  /** ci sa deje nieco skipnutelne */
  private skippable = true

  /** ci sa deje nieco skipnutelne v druhej faze */
  private skippableSecond = true

  /** Ci vieme skipnut false start */
  private skippableFalseStart = false

  /** ci uz je mozne odstartovat */
  public startable = false

  /** ci uz zobrazit ui player-info-avatar */
  private showName = false

  /** ci bolo skipnute */
  private skipped = false

  /** ci bolo skipnute v druhej faze */
  private skippedSecond = false

  /** ci faza skoncila */
  public ended = false

  /** hodnota na ktorej stlacil */
  public clickedPower = 0

  /** Pocet frameov od zaciatku fazy */
  private framesInPhase = 0

  /** tween na skrytie odrazovej hlasky */
  public startingMessageTween?: gsap.core.Tween

  /** tween na ukoncenie false startu */
  private falseStartFinishTween?: gsap.core.Tween

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /** ako dlho bude zobrazena hlaska na odraze */
  private STARTING_MESSAGE_DURATION = 2.15

  /** Premenna pre kameru */
  private cameraInPostIntroState = false

  /** Tween pre startovacie veci */
  private startTween?: gsap.core.Tween

  /** zobrazenie bielej ziary */
  private showShine = false

  /** Ci sa uz odstartovalo alebo nie */
  private started = false

  /** ci bol false start - resetujeme az po false starte v dalsom pokuse, nie v resete !! */
  public wasFalseStart = false

  /** prvy vystartoval */
  private firstStarted = false


  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase(): void {

    this.storeState()
    startPhaseStateManager.disableInputs()
    startPhaseStateManager.enableStartInputs()

  }

  /**
   * Zacatie fazy
   */
  public startPhase(): void {

    if (this.ended) {

      this.reset()


      if (modes.isTutorial()) {

        // musime doplnit vsade hracovu poziciu pre kamerove obejkty a potom nastavit tweeny
        player.updateCameraConfigOnStart(CameraStates.disciplineIntro)

        if (gameConfig.cameraConfig.enabled) {

          player.setGameCameraSettings()

        }
        this.afterCameraDisciplineIntro()
        this.removeBlackOverlay()
        return

      }

      this.secondCameraDisciplineIntro()
      this.removeBlackOverlay()
      return

    }

    if (!modes.isTutorial()) audioManager.play(AudioNames.audienceHyped)
    audioHelper.stopAudioWithDelay(AudioNames.audienceIntro)

    console.warn('starting start phase')
    // Zobrazit meno hraca v UI
    this.showName = !modes.isTutorial()
    worldEnv.setVisibilityText100m(false)
    this.setCameraForDisciplineIntro()

  }

  /**
   * Nastavenie kamery pre intro
   */
  private setCameraForDisciplineIntro(): void {

    // musime doplnit vsade hracovu poziciu pre kamerove obejkty a potom nastavit tweeny
    player.updateCameraConfigOnStart(CameraStates.disciplineIntro)

    if (gameConfig.cameraConfig.enabled) {

      player.setGameCameraSettings()

    }
    if (modes.isTutorial()) {

      this.afterCameraDisciplineIntro()
      tutorialFlow.init()
      tutorialUIChange.init()
      return

    }

    // musime zmenit hracovu animaciu
    player.setState(PlayerStates.prepareSpecial)

    cameraManager.setState(CameraStates.disciplineIntro)
    cameraManager.playTween(false, this.secondCameraDisciplineIntro)

  }

  /** pomocna metoda pre animacie */
  public getCameraInPostIntroState(): boolean {

    return this.cameraInPostIntroState

  }

  /**
   * Druhe disciplinove kamerove intro
   */
  private secondCameraDisciplineIntro = (): void => {

    this.skippable = false
    // Zrusit zobrazenie mena hraca v UI
    this.showName = false

    worldEnv.changeLightmapFromDarkToNormal()

    if (modes.isTrainingMode() || this.wasFalseStart) {

      this.wasFalseStart = false
      this.afterCameraDisciplineIntro()
      return

    }

    // musime dat chvilku pauzu, aby sa dalo skipovat 2x po sebe
    gsap.to({}, {
      duration: 0.1,
      onComplete: () => {

        worldEnv.showFlagsIntro()

        // musime zmenit hracovu animaciu naspat
        player.setState(PlayerStates.prepare2)

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cameraManager.setState(CameraStates.disciplineIntroSecond)
        cameraManager.playTween(false, this.afterCameraDisciplineIntro)

        this.skippableSecond = true

      }
    })

  }

  /**
   * Spravenie veci po konci disciplinoveho intra
   */
  private afterCameraDisciplineIntro = (): void => {

    this.skippable = false
    this.skippableSecond = false

    worldEnv.setVisibilityGroundFlags(false)

    // nastavime stav hracom, aby isli aj animacie
    player.setState(PlayerStates.onYourMarks)
    opponentsManager.setStateToAll(PlayerStates.onYourMarks)

    // nastavime spravnu poziciu
    player.worldEnvLinesManager.setActualPercentOnStart()
    opponentsManager.setActualPercentOnStart()

    this.cameraInPostIntroState = true

    cameraManager.setState(CameraStates.discipline)
    cameraManager.changeBaseRenderSettings(undefined, undefined, gameConfig.cameraConfig.fov)

    startPhaseStateManager.postIntroUiState()

    if (modes.isTutorial() && disciplinePhasesManager.attempt === 1) return
    this.launchSystem()

  }

  /**
   * Launch system
   */
  public launchSystem(): void {

    if (!modes.isTrainingMode() && !modes.isTutorial()) store.commit('GamePhaseState/SET_SHOW_RECORDS', true)

    const showFirstLine = true
    const showSecondLine = false

    startPhaseStateManager.resetTextMessageFinishedEmits(showFirstLine, showSecondLine)
    store.commit('TextMessageState/SET_STATE', {
      showFirstLine,
      showSecondLine,
      firstLineText: '100m-start-texts',
      firstLineTextType: 0,
      showMessage: true,
      showType: 3
    })

    if (!modes.isTutorial()) audioManager.fadeOutAudio(AudioNames.audienceHyped, 1000)

    // zobrazime, v ktorej drahe je hrac, ale najskor si urcime poziciu hraca na x-ovej osi
    worldEnv.setPlayerPositionX()
    worldEnv.setVisibilityTextPlayer(true)

    console.log('ON YOUR MARKS')
    this.storeState()

    this.startTween = gsap.to({}, {

      onComplete: this.stateSet,
      callbackScope: this,
      duration: 1.5

    })

  }

  /**
   * Stav startu - set
   */
  private stateSet(): void {

    // nastavime stav hracom, aby isli aj animacie
    player.setState(PlayerStates.set)
    opponentsManager.setStateToAll(PlayerStates.set, startConfig.maxRandomDelaySet)

    const showFirstLine = true
    const showSecondLine = false

    startPhaseStateManager.resetTextMessageFinishedEmits(showFirstLine, showSecondLine)
    store.commit('TextMessageState/SET_STATE', {
      showFirstLine,
      showSecondLine,
      firstLineText: '100m-start-texts',
      firstLineTextType: 1,
      showMessage: true,
      showType: 3
    })

    audioManager.play(AudioNames.judgeSet)

    store.commit('StartDebugPhaseState/SET_ACTIVE', true)

    this.startTween = gsap.to({}, {

      onComplete: this.stateShot,
      callbackScope: this,
      duration: 2,
      onUpdate: () => {

        if (this.startTween) {

          store.commit(
            'StartDebugPhaseState/SET_MARK_POSITION',
            this.startTween.progress() * 100
          )

        }


      }

    })

  }

  /**
   * Stav startu - vystrel
   */
  private stateShot(): void {

    this.started = true
    timeManager.setActive(TimesTypes.game, true)
    store.commit('UiState/SET_TIME_VISIBILITY', true)

    startPhaseStateManager.showStartMessageInUI(
      {
        message: 'Start',
        color: 0,
        messageType: 2
      },
      2
    )
    opponentsManager.start()
    console.log('START SHOT')
    this.showShine = true
    audioManager.play(AudioNames.startShot)

    // resetujeme framey, aby sme vedeli, ci nebol predcasny start
    this.framesInPhase = 0
    // store.commit('TutorialState/SET_SHOW_BAR_START', false)
    store.commit('ActionButtonState/SET_IS_ACTION_DISABLED', false)
    startPhaseStateManager.enableInputs()

    audioHelper.setTimerToChangeAudienceAfterStart()

    gsap.to({}, {
      onComplete: () => {

        this.showShine = false
        store.commit('StartDebugPhaseState/SET_ACTIVE', false)

        store.commit('StartPhaseState/SET_SHOW_SHINE', this.showShine)

      },
      duration: 0.2
    })

  }

  /**
   * Zacatie hybania sa hraca
   */
  private startMovingPlayer(): void {

    this.startable = false

    // schovame, v ktorej drahe je hrac
    worldEnv.setVisibilityTextPlayer(false)

    this.wasFalseStart = this.framesInPhase < 3 || !this.started
    this.trainingStartQuality()
    if (!this.wasFalseStart) disciplinePhasesManager.phaseRunning.startCollectingQualityRun()
    this.setStartQuality()
    console.log(`hrac odstartoval vo frame ${ this.framesInPhase}, falseStart ${this.wasFalseStart}`)

    store.commit('GamePhaseState/SET_SHOW', false)
    store.commit('ActionButtonState/SET_START_BUTTON', false)

    if (this.wasFalseStart) {

      this.makeFalseStart()
      return

    }
    if (this.framesInPhase <= 10) {

      tutorialObjectives.passObjective(TutorialObjectiveIds.start as string)

    } else {

      tutorialObjectives.failObjective(TutorialObjectiveIds.start as string)

    }
    this.finishPhase()
    // this.ended = true

  }

  /**
   * nastavenie start quality pre logovanie
   */
  private setStartQuality(): void {

    const { startQualities } = trainingConfig

    let delta = this.framesInPhase
    if (delta < 1 || !this.started) delta = 0
    if (delta > 15) delta = 15

    endManager.startQuality = startQualities[delta] / 100

  }

  /**
   * Ziskanie kvality startu v treningu
   */
  private trainingStartQuality() {

    if (!modes.isTrainingMode() || this.wasFalseStart) return
    const { startQualities } = trainingConfig

    let delta = this.framesInPhase
    // Na prvy pokus ak sa pokazi sa to ignoruje
    if (disciplinePhasesManager.attempt === 1 && (delta < 0 || !this.started)) return
    if (delta < 1 || !this.started) delta = 0
    if (delta > 15) delta = 15
    const value = startQualities[delta] / 100
    trainingTasks.saveTaskValue(Tasks.startQuality, value)

  }

  /**
   * Inputy pri starte
   */
  public handleInputs(): void {

    if (this.ended) return
    // odstartovanie
    if (this.startable) {

      this.startMovingPlayer()

    }

    // skip intra
    if (this.skippable && this.framesInPhase > 10) {

      console.log('skippped')
      this.skippable = false
      this.skipped = true
      cameraManager.skipTween()
      this.framesInPhase = 0

    }

    // skip intra druheho
    if (this.skippableSecond && this.framesInPhase > 10) {

      this.skippableSecond = false
      this.skippedSecond = true
      cameraManager.skipTween()
      this.framesInPhase = 0

    }

    // skip false startu
    if (this.skippableFalseStart) {

      this.skipFalseStart()

    }

  }

  /**
   * Update kazdy frame
   */
  public update(): void {

    if (!this.ended) this.framesInPhase++

    const { isEnabled, startFrames } = gameConfig.autoMove
    if (this.started && isEnabled && this.framesInPhase >= startFrames && !this.wasFalseStart) {

      // auto odstartovanie
      console.log(`auto start po ${this.framesInPhase} frameoch`)
      this.startMovingPlayer()
      player.velocityManager.setSpeedPower(200)

    }

    this.storeState()

  }

  /**
   * Vykonanie false startu
   */
  private makeFalseStart(): void {

    this.startTween?.kill()

    if (!this.started) {

      // dame prvy vystrel
      audioManager.play(AudioNames.startShot)
      audioHelper.setTimerToChangeAudienceAfterStart()
      console.log('start shot false start - first')

    }

    // hraci by sa mali rozbehnut
    player.start()
    opponentsManager.start()

    startPhaseStateManager.showStartMessageInUI(
      {
        message: 'falseStart',
        color: 2,
        messageType: 3
      },
      2
    )
    startPhaseStateManager.disableStartMessageInUI(
      {
        message: '',
        color: 0,
        messageType: 0
      },
      1
    )

    gsap.to({}, {
      duration: 0.5,
      onComplete: () => {

        // druhy vystrel
        audioManager.stopAudioByName(AudioNames.startShot)
        audioManager.play(AudioNames.startShot)

      }
    })

    gsap.to({}, {
      duration: 1.5,
      onComplete: () => {

        // hraci zacnu spomalovat
        player.falseStartSlowDown()
        opponentsManager.falseStartSlowDown()

        console.log('false start UI + second shot + zaciatok spomalovania')
        tutorialFlow.eventActionTrigger(TutorialEventType.failedStart)

        // v tutoriali nechceme, aby skipoval
        if (modes.isTutorial()) return

        const { attempt } = disciplinePhasesManager
        const audio = attempt > 1 ? AudioNames.commentatorSecondFalseStart : AudioNames.commentatorFalseStart
        audioManager.play(audio)

        gsap.to({}, {
          duration: 0.5,
          onComplete: () => {

            // moznost skipnut
            this.skippableFalseStart = true

          }
        })

        this.falseStartFinishTween = gsap.to({}, {
          duration: 3,
          onComplete: () => {

            // koniec false startu
            this.finishFalseStart()

          }
        })

      }
    })

  }

  /**
   * Skipnutie false startu
   */
  public skipFalseStart(): void {

    this.falseStartFinishTween?.kill()

    // musime vypnut tweeny pre slow down run
    player.athleteAnimationManager.tweenSlowDownRun?.kill()
    opponentsManager.getOpponents().forEach((opponent) => {

      opponent.athleteAnimationManager.tweenSlowDownRun?.kill()

    })

    this.skippableFalseStart = false

    this.finishFalseStart()

  }

  /**
   * Create black overlay
   */
  public createBlackOverlay(): void {

    store.commit('BlackOverlayState/SET_BLACK_SCREEN', true)

  }

  /**
   * Remove black overlay
   */
  public removeBlackOverlay(): void {

    store.commit('BlackOverlayState/SET_BLACK_SCREEN', false)

  }

  /**
   * Ukoncenie false startu
   */
  public finishFalseStart(): void {

    // cierna obrazovka a s nou reset veci
    this.createBlackOverlay()
    this.ended = true
    console.log('finishFalseStart', tutorialManager.getActualSectionId())
    gsap.to({}, {
      onComplete: () => {

        console.warn('restart')
        disciplinePhasesManager.resetAttempt()

      },
      duration: 0.5
    })

  }

  /**
   * Ukoncene fazy
   */
  public finishPhase(): void {

    if (!this.ended) {

      console.warn('start phase ended')
      this.showStartMessage()

      this.ended = true
      this.callbackEnd()
      player.start()
      tutorialFlow.eventActionTrigger(TutorialEventType.goodStart)

      this.storeState()

      startPhaseStateManager.finishPhaseUiState()
      // startPhaseStateManager.disableInputs()

    }

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

  /**
   * UI update
   */
  private storeState(): void {

    store.commit('StartPhaseState/SET_STATE', {
      showName: this.showName,
      showPhase: !this.ended,
      attempt: corePhasesManager.disciplineActualAttempt,
      showShine: this.showShine
    })

  }

  /**
   * zobrazime startovu spravu
   */
  private showStartMessage(): void {

    if (this.wasFalseStart) return
    const message = this.getStartingMessage()
    startPhaseStateManager.showStartMessageInUI(message, 1)

    this.startingMessageTween = gsap.to({}, {
      duration: this.STARTING_MESSAGE_DURATION,
      onComplete: () => {

        startPhaseStateManager.disableStartMessageInUI(
          {
            message: '',
            color: 0,
            messageType: 0
          },
          1
        )
        startPhaseStateManager.disableStartMessageInUI(
          {
            message: '',
            color: 0,
            messageType: 0
          },
          2
        )

      }
    })

  }

  /**
   * ziskame startovu spravu
   */
  private getStartingMessage(): DisplayMessage {

    const message = {
      message: 'slowStart',
      color: 2
    }
    const { perfect, excellent, good } = startConfig.startMessageFrames
    let audio = AudioNames.commentatorStartPoor

    if (this.framesInPhase <= perfect) {

      message.message = 'perfectStart'
      message.color = 0
      audio = AudioNames.commentatorStartPerfect
      endManager.perfectOrExcellentStart = true

    } else if (this.framesInPhase <= excellent) {

      message.message = 'excellentStart'
      message.color = 0
      audio = AudioNames.commentatorStartPerfect
      endManager.perfectOrExcellentStart = true

    } else if (this.framesInPhase <= good) {

      message.message = 'goodStart'
      message.color = 1
      audio = AudioNames.commentatorStartGood

    }

    audioManager.play(audio)

    return message

  }

  /**
   * Po vystartovani prveho bezca
   */
  public manageFirstStarted(): void {

    if (
      this.firstStarted ||
      (modes.isTutorial() && !tutorialFlow.isSecondRun)
    ) return

    this.firstStarted = true

    gsap.to({}, {
      duration: 1,
      onComplete: () => {

        worldEnv.setVisibilityLineLeader(true)

      }
    })

  }

  /**
   * reset fazy
   */
  private reset(): void {

    store.commit('InputsState/SET_DISABLED', true)
    this.skippable = true
    this.skippableSecond = false
    this.skippableFalseStart = false
    this.startable = false
    this.showName = false
    this.skipped = false
    this.skippedSecond = false
    this.ended = false
    this.clickedPower = 0
    this.started = false
    this.framesInPhase = 0
    this.showShine = false
    this.startTween = undefined
    this.falseStartFinishTween = undefined
    this.startingMessageTween = undefined
    this.cameraInPostIntroState = false
    this.firstStarted = false
    this.storeState()
    startPhaseStateManager.disableStartMessageInUI(
      {
        message: '',
        color: 0,
        messageType: 0
      },
      1
    )
    startPhaseStateManager.disableStartMessageInUI(
      {
        message: '',
        color: 0,
        messageType: 0
      },
      2
    )

  }

}

<template>
  <div
    v-if="gamePhaseState.showSmallActionButtons"
    class="speed-bar"
  >
    <speed-bar v-if="showBar" />
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import SpeedBar from './SpeedBar.vue'

import { defineComponent } from 'vue'
import { modes } from '@powerplay/core-minigames'

export default defineComponent({
  name: 'EfficiencyComponent',
  components: {
    SpeedBar
  },
  props: {
    isMobile: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      startPhaseState: 'StartPhaseState/getStartPhaseState',
      getActionDisabled: 'ActionButtonState/getActionDisabled',
      tutorialState: 'TutorialState/getTutorialState',
      gamePhaseState: 'GamePhaseState/getGamePhaseState'
    }),
    showBar() {

      if (modes.isTutorial() && this.tutorialState.showSpeedBar) return false
      return this.gamePhaseState.showBar

    }
  }
})

</script>

<style lang='less' scoped>
.speed-bar {
  position: absolute;
  top: 60%;
  left: 55%;
  transform: translate(-50%, -50%);
}
</style>


/**
 * Mena sekcie
 */
export enum SectionNames {
  mobileSidePick = 'mobileSidePick',
  introductionNewInstructor = 'introductionNewInstructor',
  startSection = 'startSection',
  startSectionSecond = 'startSectionSecond',
  startSectionThird = 'startSectionThird',
  startSectionFourth = 'startSectionFourth',
  startSectionFifth = 'startSectionFifth',
  startSectionSixth = 'startSectionSixth',
  startSectionSeventh = 'startSectionSeventh',
  startSectionEight = 'startSectionEight',
}

type NameKeys = keyof typeof SectionNames;
type NameKeyFields = { [key in NameKeys]: string }

export interface UiState extends NameKeyFields {
  NameKeyFields: () => void
}

export enum TutorialEventType {
  failedStart = 'failedStart',
  goodStart = 'goodStart',
  beforeFirstFinish = 'beforeFirstFinish',
  firstFinish = 'firstFinish',
  goodFinish = 'goodFinish',
  okFinish = 'okFinish',
  badFinish = 'badFinish',
  autoLunge = 'autoLunge',
  tooSlow = 'tooSlow',
  tooFast = 'tooFast',
  awaitingEvent = 'awaitingEvent'
}

export enum TutorialObjectiveIds {
  start = 'tutorialTask1',
  lunge = 'tutorialTask2',
  beatOpponent = 'tutorialTask3',
}

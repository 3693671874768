import type { AnimationsSettingRepository } from '@powerplay/core-minigames'
import { PlayerAnimationsNames } from '../types'
/**
 * Konfig pre animacie
 */
export const animationsConfig: AnimationsSettingRepository = {

  [PlayerAnimationsNames.prepare]: {
    loop: true,
    starting: true
  },
  [PlayerAnimationsNames.prepare1]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.prepare2]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.prepare3]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.prepare4]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.prepareHand]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.prepareHands]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.prepareSalut]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.prepareKisses]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.idleGroundOnStart]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.groundToSet]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.setToRun]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.startRun]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.run]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.lunge]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.slowDownRun]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.endHappy1]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.endHappy2]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.endHappy3]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.stopToBreathing]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.breathing]: {
    loop: true,
    starting: false
  },

}

/** Specialny konfig pre animacie */
export const specialAnimationConfig = {

  /** randomizovanie animacii podla cisla trate */
  randomPercentByPathIndex: [0, 12.5, 25, 37.5, 50, 62.5, 75, 87.5].sort(() => (Math.random() - 0.5)),

  /** randomizacia pre animaciu ground to set */
  groundToSet: {

    // min a max pre random v sekundach
    min: 0,
    max: 0.2

  },

  /** randomizacia pre animaciu run */
  run: {

    // min a max pre random v sekundach
    min: 0,
    max: 0.2

  },

  /** randomizacia pre animaciu slow down run */
  slowDownRun: {

    // min a max pre random v sekundach
    min: 0,
    max: 0.2

  }

}

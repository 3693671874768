import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface GamePhaseState {
  showBar: boolean,
  speedPower: number,
  showSmallActionButtons: boolean,
  disabledSmallAction: boolean,
  showUi: boolean,
  mark: number,
  showRecords: boolean
}

const initialState = () => ({
  showBar: false,
  speedPower: 0,
  showSmallActionButtons: true,
  disabledSmallAction: false,
  showUi: true,
  mark: 100,
  showRecords: false
})

const gamePhaseState: Module<GamePhaseState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getGamePhaseState: (moduleState: GamePhaseState) => moduleState,
    getSpeed: (moduleState: GamePhaseState) => moduleState.speedPower,
    getSmallActionButtons: (moduleState: GamePhaseState) => moduleState.showSmallActionButtons,
    getMark: (moduleState: GamePhaseState) => moduleState.mark,
    getDisabledSmallAction: (moduleState: GamePhaseState) => moduleState.disabledSmallAction,
    getShowRecords: (moduleState: GamePhaseState) => moduleState.showRecords,
  },

  mutations: {
    RESET: (moduleState: GamePhaseState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: GamePhaseState, newState: GamePhaseState) => {

      if (moduleState.showBar !== undefined) moduleState.showBar = newState.showBar
      if (moduleState.speedPower !== undefined) moduleState.speedPower = newState.speedPower

    },
    SET_MARK: (moduleState: GamePhaseState, mark: number) => {

      moduleState.mark = mark

    },
    SET_SHOW_UI: (moduleState: GamePhaseState, showUi: boolean) => {

      moduleState.showUi = showUi

    },
    SET_SMALL_ACTION: (moduleState: GamePhaseState, showButtons: boolean) => {

      moduleState.showSmallActionButtons = showButtons

    },
    SET_SHOW: (moduleState: GamePhaseState, isShown: boolean) => {

      moduleState.showBar = isShown

    },
    SET_SPEED: (moduleState: GamePhaseState, power: number) => {

      moduleState.speedPower = power

    },
    SET_DISABLED_SMALL_BUTTONS: (moduleState: GamePhaseState, disabled: boolean) => {

      moduleState.disabledSmallAction = disabled

    },
    SET_SHOW_RECORDS: (moduleState: GamePhaseState, show: boolean) => {

      moduleState.showRecords = show

    }

  }
}

export default gamePhaseState

<template>
  <div
    v-if="getShowRecords"
    class="boxes-records"
    :style="getStyle"
  >
    <record-box
      :yellow-text="worldRecord"
      :is-scaled="false"
      blue-text="WR"
    />
    <record-box
      v-if="showPersonalBest"
      :yellow-text="personalBest"
      :is-scaled="false"
      blue-text="PB"
      class="second-box"
    />
  </div>
</template>

<script lang="ts">
/* eslint-disable max-len */
import {
  RecordBox,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'
import { mapGetters } from 'vuex'

import { defineComponent } from 'vue'
import {
  playersManager,
  timeManager
} from '@powerplay/core-minigames'
import { endManager } from '@/app/EndManager'

export default defineComponent({
  name: 'BoxesRecords',
  components: {
    RecordBox
  },
  mixins: [WindowAspect],
  computed: {
    ...mapGetters({
      getShowRecords: 'GamePhaseState/getShowRecords',
    }),
    worldRecord() {

      return timeManager.getTimeInFormatFromSeconds(endManager.worldRecord)

    },
    personalBest() {

      return timeManager.getTimeInFormatFromSeconds(playersManager.getPlayer().personalBest)

    },
    showPersonalBest() {

      return playersManager.getPlayer().personalBest > 0

    },
    getStyle() {

      return {
        transform: `scale(${this.scaleCoef})`,
        'transform-origin': 'left top'
      }

    }
  }
})
</script>

<style lang="less" scoped>
  .boxes-records {
    position: absolute;
    left: 1%;
    top: 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 143px;
  }
</style>

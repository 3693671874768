import { TexturesNames } from '../types'

/**
 * Specialny konfig pre sponzorov v hre
 */
export const sponsorsConfig = {

  // bannery
  banners: [

    {
      name: 'vshosting',
      textureName: TexturesNames.colorGradientVshosting,
      active: true,
      times: [

        {
          from: 1722463200000, // 1.8.2024
          to: 1723672800000, // 15.8.2024
          percent: 0.3 // 30%
        },
        {
          from: 1726092000000, // 12.9.2024
          to: 1727301600000, // 26.9.2024
          percent: 0.1 // 10%
        },
        {
          from: 1727301600000, // 26.9.2024
          to: 1728511200000, // 10.10.2024
          percent: 0.3 // 30%
        }

      ]
    }

  ]

}

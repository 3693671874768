import {
  pathAssetsGlobal,
  pathAssets
} from '@/globals/globalvariables'
import type { LoaderDataTypes } from '@powerplay/core-minigames'
import { ModelsNames } from '../types'

// Hlavny priecinok s modelmi
const modelsDir = `${pathAssets}/models/`
const modelsDirGlobal = `${pathAssetsGlobal}/models/`

/**
 * Konfig pre modely
 */
export const modelsConfig: LoaderDataTypes = {
  [ModelsNames.athlete]: {
    ext: 'glb',
    genderActive: true,
    dir: modelsDir,
    version: 240503,
    femaleVersion: 240503,
    mainMeshNames: [ModelsNames.athlete],
    opponentCheck: true
  },
  [ModelsNames.env]: {
    ext: 'glb',
    version: 2403113,
    dir: modelsDir
  },
  [ModelsNames.stadium]: {
    ext: 'glb',
    version: 2403113,
    dir: modelsDirGlobal
  },
  [ModelsNames.envDynamic]: {
    ext: 'glb',
    version: 240514,
    dir: modelsDir
  },
  [ModelsNames.trackLines]: {
    ext: 'glb',
    version: 202309292,
    dir: modelsDir
  },
  // [ModelsNames.cameraBoxes]: {
  //   ext: 'glb',
  //   version: 240226,
  //   dir: modelsDir,
  // }

}

<template>
  <div
    :class="isMobile() ? 'positioner' : 'nomobile-positioner'"
    :style="addStyle"
  >
    <speed-bar-v-2
      :fill-size="speed"
      :mark="mark"
      show-mark
      show-arrows
      :is-scaled="isScaled"
      :class="{'speed-bar-mobile' : isMobile()}"
    />
  </div>
</template>

<script lang="ts">
import {
  WindowAspect,
  SpeedBarV2
} from '@powerplay/core-minigames-ui-ssm'
import { mapGetters } from 'vuex'

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    SpeedBarV2
  },
  mixins: [WindowAspect],
  props: {
    isLeft: {
      type: Boolean,
      default: false
    },
    isScaled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      speed: 'GamePhaseState/getSpeed',
      mark: 'GamePhaseState/getMark'
    }),
    addStyle() {

      if (this.isLeft) return 'justify-content: flex-end;'
      return ''

    }
  },
})

</script>

<style scoped lang="less">
.positioner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    pointer-events: none;
    .power-start-bar {
        transform-origin: center bottom;
    }
}

.nomobile-positioner {
    pointer-events: none;
    .power-start-bar {
        transform-origin: center bottom;
    }
}
.speed-bar-mobile {
  right: 60%;
  margin-top: 60%;
}
</style>

/**
 * Konfig pre vlajky
 */
export const flagsConfig = {

  // pole krajin zlava doprava, zhora dole
  countries: [

    'USA', 'GBR', 'CHN', 'FRA', 'ITA', 'DEU', 'HUN', 'JPN',
    'AUS', 'RUS', 'SWE', 'FIN', 'KOR', 'NLD', 'ROU', 'CUB',
    'POL', 'CAN', 'NOR', 'BGR', 'NZL', 'CHE', 'ESP', 'DNK',
    'BEL', 'TUR', 'BRA', 'GRC', 'UKR', 'ZAF', 'JAM', 'IRN',
    'ARG', 'AUT', 'CZE', 'HRV', 'MEX', 'BLR', 'IRL', 'SVK',
    'EST', 'SVN', 'AZE', 'SRB', 'LTU', 'PRT', 'COL', 'LVA',
    'ISR', 'BIH', 'CYP', 'MDA', 'MNE', 'MKD', 'CHL', 'ISL',
    'DZA', 'MYS', 'EGY', 'MAR', 'GEO', 'ARM', 'IND', 'TUN',
    'SMR', 'LIE', 'ALB', 'AND', 'MLT', 'ECU', 'MCO', 'IDN',
    'BHS', 'UZB', 'THA', 'KAZ', 'KEN', 'ETH', 'CMR', 'ZWE',
    'PAK', 'TTO', 'VEN', 'NGA', 'LUX', 'BHR', 'QAT', 'URY',
    'ARE', 'CRI', 'CIV', 'PER', 'SGP', 'VNM', 'PHL', '---'

  ],

  // pocet krajin horizontalne v spritesheete
  horizontalCount: 8,

  // horizontalna hodnota posunu pre offset
  horizontalValue: 0.125,

  // pocet krajin vertikalne v spritesheete
  verticalCount: 12,

  // vertikalna hodnota posunu pre offset
  verticalValue: 0.083007,

  // pole nazvov meshov pre drahy
  pathMeshNames: [
    'envDynamic_StartFlag_', 'envDynamic_StartText_', 'envDynamic_StartFlagsBG_'
  ],

  // abeceda pre texty krajin
  alphabet: {

    // horizontalna hodnota posunu pre offset
    horizontalValue: 1 / 6,

    // vertikalna hodnota posunu pre offset
    verticalValue: 1 / 5,

    // konfig pre pismena na starte pre kraijny
    indexes: {
      'A': [0, 0],
      'B': [0, 1],
      'C': [0, 2],
      'D': [0, 3],
      'E': [0, 4],
      'F': [5, 0],
      'G': [5, 1],
      'H': [5, 2],
      'I': [5, 3],
      'J': [5, 4],
      'K': [4, 0],
      'L': [4, 1],
      'M': [4, 2],
      'N': [4, 3],
      'O': [4, 4],
      'P': [3, 0],
      'Q': [3, 1],
      'R': [3, 2],
      'S': [3, 3],
      'T': [3, 4],
      'U': [2, 0],
      'V': [2, 1],
      'W': [2, 2],
      'X': [2, 3],
      'Y': [2, 4],
      'Z': [1, 0],
    },

  }


}

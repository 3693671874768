import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface EmotionMessagesState {
  showMessage: boolean,
  firstLineText: string,
  firstLineTextSecond: string,
  secondLineText: number,
  secondLineTextSecond: string,
}

const initialState = () => ({
  showMessage: false,
  firstLineText: '',
  firstLineTextSecond: '',
  secondLineText: 0,
  secondLineTextSecond: '',
})

const emotionMessagesState: Module<EmotionMessagesState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getEmotionMessagesState: (moduleState: EmotionMessagesState) => moduleState
  },

  mutations: {
    RESET: (moduleState: EmotionMessagesState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: EmotionMessagesState, newState: EmotionMessagesState) => {

      if (newState.showMessage !== undefined) moduleState.showMessage = newState.showMessage
      if (newState.firstLineText !== undefined) moduleState.firstLineText = newState.firstLineText
      if (newState.firstLineTextSecond !== undefined) moduleState.firstLineTextSecond = newState.firstLineTextSecond
      if (newState.secondLineText !== undefined) moduleState.secondLineText = newState.secondLineText
      if (newState.secondLineTextSecond !== undefined) moduleState.secondLineTextSecond = newState.secondLineTextSecond

    }
  }
}

export default emotionMessagesState

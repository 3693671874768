/**
 * Konfig pre paths
 */
export const pathsConfig = {

  /** pocet drah */
  tracks: 8,

  /** percenta pre pociatocne pozicie hracov */
  positionsStartIntro: [0.045, 0.045, 0.045, 0.045, 0.045, 0.045, 0.045, 0.045],

  /** offset pre positionsStartIntro pre nahodne umiestnenie */
  positionsIntroOffset: {
    min: 0.005,
    max: 0.01
  },

  /** percenta pre pociatocne pozicie hracov */
  positionsStart: [
    0.06969206511415414,
    0.06898140242550721,
    0.06862884948553612,
    0.06819248830428157,
    0.06763229406563033,
    0.06772350906962606,
    0.06858334828892983,
    0.0695
  ],

  /** kedy ma zacat finish faza podla % na krivke */
  positionsFinish: [
    0.7239952663657451,
    0.7166125546937582,
    0.7129500622813967,
    0.7084169288588673,
    0.7025973570559007,
    0.7035449431405756,
    0.7124773735914723,
    0.722
  ],

  /** sirka od jednej drahy k druhej - vypocitane */
  pathWidth: 1.229,

}

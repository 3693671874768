import { gameConfig } from '@/app/config'
import { gauss } from '@powerplay/core-minigames'

/**
 * Vietor
 */
export class Wind {

  /** Hodnota vetra */
  private actualValue = 0

  /**
   * Vratenie hodnoty vetra
   * @returns Vietor
   */
  public getValue(): number {

    return this.actualValue

  }

  /**
   * Nastavenie hodnoty vetra
   */
  public setValue(): void {

    this.actualValue = gauss.getValue(0, 10, -20, 20) / 10
    if (gameConfig.configWind.isEnabled) this.actualValue = gameConfig.configWind.value

    console.log('vygenerovany vietor je ', this.actualValue)

  }

  /**
   * Vratenie stringu hodnoty vetra aj so znamienkom
   * @returns String hodnoty vetra
   */
  public getValueWithSignAsString(): string {

    const windValue = wind.getValue()
    let windValueString = windValue.toFixed(1)
    if (windValue > 0) windValueString = `+${windValueString}`
    return windValueString

  }

}

export const wind = new Wind()

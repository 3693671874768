import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface PlayerState {
  maxSpeed: number,
  speed: number
}

const initialState = () => ({
  maxSpeed: 1,
  speed: 0
})

const mainState: Module<PlayerState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getMainState: (moduleState: PlayerState) => moduleState
  },

  mutations: {
    RESET: (moduleState: PlayerState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: PlayerState, newState: PlayerState) => {

      moduleState.maxSpeed = newState.maxSpeed
      moduleState.speed = newState.speed

    }
  }
}

export default mainState

<template>
  <div>
    <div
      :class="isMobile() ? 'positioner' : 'nomobile-positioner'"
    >
      <div
        :class="['arrow-bar-wrapper', {'speed-bar-mobile' : isMobile()}]"
        :style="transformCoef"
      >
        <arrow-animation
          style="position: absolute; height: 80px; width: 100%; left: 30px"
          position="left"
          :is-scaled="false"
        />
      </div>
      <speed-bar-v-2
        :fill-size="80"
        :mark="70"
        show-mark
        show-arrows
        glow
        :class="{'speed-bar-mobile' : isMobile()}"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  WindowAspect,
  SpeedBarV2,
  ArrowAnimation
} from '@powerplay/core-minigames-ui-ssm'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TutorialBars',
  components: {
    SpeedBarV2,
    ArrowAnimation
  },
  mixins: [WindowAspect],
  computed: {
    transformCoef() {

      return {
        transform: `scale(${ this.scaleCoef }) translate(0, 0)`
      }

    }
  }
})
</script>

<style scoped lang="less">
.tutorial {
    .nomobile-start-bar {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 375px;
        height: 375px;
    }
    .positioner {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        pointer-events: none;
        .power-start-bar {
            transform-origin: center bottom;
        }
    }

    .nomobile-positioner {
        pointer-events: none;
        .power-start-bar {
            transform-origin: center bottom;
        }
    }
    .speed-bar-mobile {
      right: 60%;
      margin-top: 60%;
    }

    .arrow-bar-wrapper {
      position: absolute;
      width: 46px;
      height: 271px;
    }
}
</style>

/**
 * Konfig pre opponenta/ov
 */
export const opponentConfig = {

  /** defaultMaxSpeedBar */
  defaultMaxSpeedBarValue: 100,

  /** divider na fake casy v cieli */
  fakeTimeMetersDivider: 8,

  /** pocty framov medzi ktorymi je random delay startu opponenta */
  startFrames: {

    from: 4,
    to: 7

  },

  /** hodnoty pre speedbar */
  speedbar: {

    /** kolko hodnot speedbaru sa bude generovat */
    valuesToGenerate: 85,

    /** default hodnota pre generovanie */
    valueDefault: 200,

    /** specialna hodnota pre generovanie */
    valueSpecial: {

      from: 199,
      to: 206,

      /** Kazda x-ta hodnota bude specialna */
      everyX: 8

    },

    /** Kazdy x-ty frame sa bude menit hodnota speedbaru */
    changeValueEveryX: 6

  },

  /** hodnoty pre lunge */
  lunge: {

    /** hodnoty v metroch (odratane od ciela), kedy sa ma davat lunge superov */
    metersBeforeFinish: {

      max: 6,
      min: 2

    }

  }

}

import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface TimerState {
  time: string
}

const initialState = () => ({
  time: '0.0'
})

const timeState: Module<TimerState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getTimeState: (moduleState: TimerState) => moduleState,
    getTime: (moduleState: TimerState) => moduleState.time
  },

  mutations: {
    RESET: (moduleState: TimerState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: TimerState, newState: TimerState) => {

      moduleState.time = newState.time

    }
  }
}

export default timeState

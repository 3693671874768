import { createStore } from 'vuex'

import { CoreState } from '@powerplay/core-minigames-ui-ssm'
import MainState from './modules/mainState'
import StartPhaseState from './modules/startPhaseState'
import TimeState from './modules/timeState'
import DirectionsState from './modules/directionsState'
import InputsState from './modules/inputsState'
import InstructionsState from './modules/instructionsState'
import UiState from './modules/uiState'
import TableState from './modules/tableState'
import ActionButtonState from './modules/actionButtonState'
import StartMessageState from './modules/startMessageState'
import MovementState from './modules/movementState'
import DebugState from './modules/debugState'
import SpeedmeterState from './modules/speedmeterState'
import TrainingState from './modules/trainingState'
import TrainingResultsState from './modules/trainingResultsState'
import GamePhaseState from './modules/gamePhaseState'
import MovementButtonsState from './modules/movementButtonsState'
import CountdownState from './modules/countdownState'
import TutorialState from './modules/tutorialState'
import BlurState from './modules/blurState'
import StartDebugPhaseState from './modules/startDebugPhaseState'
import LungeState from './modules/lungeState'
import BlackOverlayState from './modules/blackOverlayState'
import EmotionMessagesState from './modules/emotionMessagesState'
import TextMessageState from './modules/textMessageState'

// TODO: Check out why we need this?
// eslint-disable-next-line
export interface RootState {}

const modules = {
  ...CoreState,
  TimeState,
  MainState,
  StartPhaseState,
  DirectionsState,
  InputsState,
  UiState,
  TableState,
  ActionButtonState,
  InstructionsState,
  StartMessageState,
  MovementState,
  DebugState,
  SpeedmeterState,
  TrainingState,
  TrainingResultsState,
  GamePhaseState,
  MovementButtonsState,
  CountdownState,
  TutorialState,
  BlurState,
  StartDebugPhaseState,
  LungeState,
  BlackOverlayState,
  EmotionMessagesState,
  TextMessageState
}

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
    clearStateAll({ dispatch }) {

      dispatch('holyHandGrenade')

    },
    holyHandGrenade({ commit }) {

      for (const currentModule in modules) {

        if (['GameSettingsState', 'FpsLookerState', 'MobileState'].includes(currentModule)) continue
        commit(`${currentModule}/RESET`)

      }

    }
  },
  modules
})

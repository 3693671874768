<template>
  <div class="hud">
    <div class="info">
      <div>Speed: {{ mainState.speed.toFixed(4) }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'

import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    ...mapGetters({
      mainState: 'MainState/getMainState',
    })
  }
})

</script>

<style lang="less" scoped>
.hud {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .info {
     display: flex;
     flex-direction: column;
  background: black;
  color: white;
  width:  16.666667%;
  }
}
</style>

import type { TranslatesReplacements } from '@powerplay/core-minigames'

/**
 * Vymeny textov pre preklady - prekonkretnu disciplinu
 */
export const translatesReplacements: TranslatesReplacements[] = [

  {
    text: 'tutorialText11-2',
    replace: [
      {
        original: '[start]',
        new: 'START'
      }
    ]
  },

]

import {
  gameConfig,
  pathsConfig,
  lungeConfig,
  opponentConfig,
  cameraConfig
} from '@/app/config'
import type { SpeedManager } from '@/app/SpeedManager/SpeedManager'
import {
  THREE,
  cameraManager
} from '@powerplay/core-minigames'
import { linesManager } from './LinesManager'
import type { Athlete } from '../athlete'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { DisciplinePhases } from '@/app/types'

/**
 * Metoda na vytvorenie ciary s ktorou sa neskor bude manipulovat na jazdenie
 */
export class WorldEnvLinesCreator {

  /** Object 3d na zmenu jazdy a smeru */
  private object3d = new THREE.Object3D()

  /** Percento kde je aktualne hrac */
  private actualPercent = 0

  /** Percento kde bol naposledy hrac */
  private lastPercent = 0

  /** Percento kde bol v cieli hrac */
  private finishPercent = 0

  /** Kolko percent je 1 meter na krivke */
  public oneMeterInPercent = 0

  /** Offset pri idealnu liniu */
  public idealLineOffset = 0

  /** Posledny offset od idealu v zakrute */
  public lastOffsetFromIdealInTurn = 0

  /** trat hraca */
  public playerPath?: THREE.CurvePath<THREE.Vector3>

  /** aktivna cesta hraca */
  public finishPhaseStarted = false

  /** Ci uz bola prehrata animacia lunge alebo nie */
  private lungeStarted = false

  /** Kolko m ma trat */
  public totalPathLength = 0

  /** V kolkatich % trate bude vykonany lunge */
  private lungePositionPercent = 0

  /** Index drahy, v ktorej zacina hrac */
  public pathIndex = 0

  /** Aktualny offset pre lunge */
  public lungeOffset = 0

  /** Dolly zoom default fov */
  private dollyZoomDefaultFov?: number

  /** Dolly zoom default z pos */
  private fovLerp = new THREE.Vector3(0, 0, 0)

  /** Pomocny vektore pre foc */
  private fovVector = new THREE.Vector3(0, 0, 0)

  /** Druy pomocny vektore pre foc */
  private fovVector2 = new THREE.Vector3(0, 0, 0)

  /** Aktualny idealny pomocny offset */
  public actualIdealOffset = new THREE.Vector3(0, 0, 0)

  /** Zakladny offset na osi z */
  private baseZIdealOffset = 0

  /**
   * Konstruktor
   * @param pathIndex - index pre trat
   * @param animationsManager - manager pre animaciu
   */
  public constructor(
    pathIndex: number,
    private callbackLunge: () => unknown,
    private callbackFinish: (actualPos: number, lastPos: number, finishPos: number) => unknown,
    private callbackEndOfPath: () => unknown,
    private athlete: Athlete
  ) {

    this.playerPath = linesManager.getPath(pathIndex)
    this.pathIndex = pathIndex

    this.setActualPercentOnStartIntro()
    this.callbackLunge = callbackLunge
    this.callbackFinish = callbackFinish
    this.calculateLineInfo()
    this.setupObject3d()

    this.actualIdealOffset = gameConfig.cameraConfig.idealOffset.clone()
    this.baseZIdealOffset = gameConfig.cameraConfig.idealOffset.z
    this.fovVector2.x = gameConfig.cameraConfig.fov

  }

  /**
   * Vratenie aktualnych percent na trati
   * @returns hodnota % na trati
   */
  public getActualPercent(): number {

    return this.actualPercent

  }

  /**
   * Vratenie poslednych percent na trati
   * @returns hodnota % na trati
   */
  public getLastPercent(): number {

    return this.lastPercent

  }

  /**
   * Vratenie cielovych percent na trati
   * @returns hodnota % na trati
   */
  public getFinishPercent(): number {

    return this.finishPercent

  }

  /**
   * Nastavenie pociatocnej pozicie pre intro
   */
  private setActualPercentOnStartIntro(): void {

    const { min, max } = pathsConfig.positionsIntroOffset
    const randomOffset = min + ((max - min) * Math.random())
    this.actualPercent = pathsConfig.positionsStartIntro[this.pathIndex - 1] + randomOffset

  }

  /**
   * Nastavenie pociatocnej pozicie pre start
   */
  public setActualPercentOnStart(): void {

    this.actualPercent = pathsConfig.positionsStart[this.pathIndex - 1]

    // ked potrebujeme skip do ciela
    if (gameConfig.skipToFinish.active) this.actualPercent = gameConfig.skipToFinish.percent

    this.setupObject3d()

  }

  /**
   * Vygenerovanie lunge pozicie
   */
  public generateLungePosition(fixMeters: number | undefined = undefined): void {

    const finishPercent = pathsConfig.positionsFinish[this.pathIndex - 1]

    if (fixMeters !== undefined) {

      this.lungePositionPercent = finishPercent - (fixMeters * this.oneMeterInPercent)
      return

    }

    const { min, max } = opponentConfig.lunge.metersBeforeFinish
    this.lungePositionPercent = THREE.MathUtils.randFloat(
      finishPercent - (max * this.oneMeterInPercent),
      finishPercent - (min * this.oneMeterInPercent)
    )
    console.log('Lunge position: ', this.lungePositionPercent)

  }

  /**
   * Skontrolovanie, ci sa ma dat lunge alebo nie
   * @param inTop3 - Ci je hrac v top3
   */
  private checkLunge(inTop3: boolean): void {

    if (
      this.athlete.playable &&
      this.athlete.tutorialAutoLunge &&
      this.actualPercent >= this.lungePositionPercent &&
      !this.lungeStarted
    ) {

      this.callbackLunge()

    }

    // hrac bude davat sam lunge cez inputy a lunge sa dava iba raz a az za definovanou poziciou
    if (this.athlete.playable || this.lungeStarted || this.actualPercent < this.lungePositionPercent) return

    this.lungeStarted = true

    // lunge budu davat superi iba ked budu v top3 aktualne
    if (inTop3) this.callbackLunge()

  }

  /**
   * Skontrolovanie, ci sa ma dat finish alebo nie
   */
  private checkFinish(): void {

    if (!this.isFinishEnabled() || this.finishPhaseStarted) return

    this.callbackFinish(
      this.actualPercent * this.totalPathLength,
      this.lastPercent * this.totalPathLength,
      (pathsConfig.positionsFinish[this.pathIndex - 1] - this.lungeOffset) *
                this.totalPathLength
    )

    this.finishPercent = this.actualPercent
    this.finishPhaseStarted = true

  }

  /**
   * Skontrolovanie, ci uz nie je koniec trate
   */
  private checkEndOfPath(): void {

    if (this.actualPercent >= 1) this.callbackEndOfPath()

  }

  /**
   * Update funkcia
   * @param speedManaager - Speed manager
   * @param isEnd - Ci ide o veci v cieli
   * @param inTop3 - Ci je hrac v top 3
   * @returns - Novy object 3D
   */
  public update(speedManager: SpeedManager, isEnd: boolean, inTop3: boolean): THREE.Object3D {

    if (speedManager.isActive()) {

      // rychlost v m/frame
      const actualSpeed = isEnd ?
        speedManager.getActualSpeedPerFrameOnEnd() :
        speedManager.getActualSpeedPerFrame()

      // rychlost v %/frame
      const speedLockDivider = disciplinePhasesManager.phaseRunning.speedLockCoef
      const actualPercentSpeed = this.oneMeterInPercent * actualSpeed * speedLockDivider

      // musime si zapamatat posledne percento
      this.lastPercent = this.actualPercent

      // pridavame aktualnu rychlost v %/frame
      this.actualPercent += actualPercentSpeed

      this.dollyZoom(speedManager)

      // kontrola veci - triggerov
      this.checkLunge(inTop3)
      this.checkFinish()
      this.checkEndOfPath()

      this.setupObject3d()

    }

    return this.object3d

  }

  /**
   * Nastavenie dolly zoomu podla pozicie hraca na trati
   * @param speedManager - Speed manager
   */
  private dollyZoom(speedManager: SpeedManager): void {

    if (!this.athlete.playable || disciplinePhasesManager.actualPhase === DisciplinePhases.finish) return
    if (this.dollyZoomDefaultFov === undefined) {

      this.dollyZoomDefaultFov = cameraManager.getMainCamera().fov
      this.fovLerp.x = this.dollyZoomDefaultFov

    }
    if (this.athlete.speedLockActive) {

      this.fovLerp.lerp(this.fovVector2, 0.2)
      cameraManager.changeBaseRenderSettings(undefined, undefined, this.fovLerp.x)
      return

    }

    const { maxFov, minSpeed, coefDiffIdealOffset, coefDiffIdealOffsetMaxFov } = cameraConfig.dollyZoom

    const speed = speedManager.getActualSpeed()

    if (speed > minSpeed) {

      const fov = this.dollyZoomDefaultFov +
        (speed / minSpeed - 1) *
        (maxFov - this.dollyZoomDefaultFov)

      this.fovVector.x = fov
      this.fovLerp.lerp(this.fovVector, 0.1)

      cameraManager.changeBaseRenderSettings(undefined, undefined, this.fovLerp.x)

    }

    const percent = (this.fovVector.x - this.dollyZoomDefaultFov) / (maxFov - this.dollyZoomDefaultFov)
    const coefIdealOffset = coefDiffIdealOffset + (percent * (coefDiffIdealOffsetMaxFov - coefDiffIdealOffset))

    // pohyb kamery blizsie k hracovi smerom ku cielu
    this.actualIdealOffset.z = this.baseZIdealOffset + (this.actualPercent * coefIdealOffset)
    cameraManager.changeIdeals(this.actualIdealOffset)

  }

  /**
   * Ci uz je hrac v cieli
   * @returns boolean
   */
  public isFinishEnabled(): boolean {

    return this.actualPercent + this.lungeOffset > pathsConfig.positionsFinish[this.pathIndex - 1]

  }

  /**
   * Ci je moznost aktivovat lunge
   * @returns True, ak ano
   */
  public isLungeEnabled(): boolean {

    const percent = this.oneMeterInPercent * lungeConfig.distanceBeforeFinish
    const positionLunge = pathsConfig.positionsFinish[this.pathIndex - 1] - percent
    return this.actualPercent > positionLunge && !this.isFinishEnabled()

  }

  /**
   * Vyratanie dlzky ciary a jedneho metra na ciare
   */
  private calculateLineInfo(): void {

    this.totalPathLength = this.playerPath?.getLength() || 0

    // takisto este potrebujeme 1m kolko je %
    this.oneMeterInPercent = 1 / this.totalPathLength

  }

  /**
   * nastavenie object3d na zaciatku
   */
  private setupObject3d(): void {

    if (this.playerPath === undefined) return

    const point = this.getNewPoint()

    const pointToLookAt = this.getNewPoint(0.0016)
    if (point) {

      this.object3d.position.set(point.x, point.y + gameConfig.yPlayerCorrection, point.z)
      if (pointToLookAt) pointToLookAt.y += gameConfig.yPlayerCorrection

    }

    if (pointToLookAt) {

      this.object3d.lookAt(pointToLookAt)

    }

  }

  /**
   * Vypocitanie noveho bodu pre hraca
   * @returns novy bod alebo undefined ak sa nieco pokazilo
   */
  private getNewPoint(offset = 0): THREE.Vector3 | undefined {

    const point = this.playerPath?.getPointAt(this.actualPercent + offset)
    if (point === undefined) return

    return point

  }

  /** reset */
  public reset(): void {

    this.setActualPercentOnStartIntro()
    this.lungeStarted = false
    this.finishPhaseStarted = false
    this.calculateLineInfo()
    this.setupObject3d()
    this.actualIdealOffset = gameConfig.cameraConfig.idealOffset.clone()

    // reset dolly zoom veci
    this.dollyZoomDefaultFov = undefined
    this.fovLerp.set(0, 0, 0)
    this.fovVector.set(0, 0, 0)
    this.fovVector2.set(0, 0, 0)
    this.baseZIdealOffset = gameConfig.cameraConfig.idealOffset.z
    this.fovVector2.x = gameConfig.cameraConfig.fov

  }

}

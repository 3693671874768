import store from '@/store'
import {
  trainingManager,
  modes,
  requestManager
} from '@powerplay/core-minigames'
import { Tasks } from '../../types'

/**
 * Trieda pre treningove ulohy
 */
export class TrainingTasks {

  /** Mnozstvo taskov */
  private NUMBER_OF_TASKS = 3

  /** ci uz sme ulozili run quality */
  private runQualitySaved = false

  /**
   * Inicializovanie treningu
   */
  public initTraining(): void {

    trainingManager.setNumberOfTasks(this.NUMBER_OF_TASKS)

  }

  /**
   * Hlavna metoda ktoru volame pri pouziti trainingovych Taskov
   * @param task - Typu Tasks, typ ulohy ktorej hodnoty sa zadavaju
   * @param valueToCount - hodnota ktora sa zadava
   */
  public countTaskValue(task: Tasks, valueToCount: number): void {

    if (!modes.isTrainingMode()) return

    if (task === Tasks.runQuality) this.countRunQuality(valueToCount)

  }

  /**
   * Zapocitanie run quality
   * @param quality - kvalita (1 ak mal spravnu rychlost, 0 ak nie)
   */
  private countRunQuality(quality: number): void {

    if (this.runQualitySaved) return

    this.changeUI(Tasks.runQuality, quality)

  }

  /**
   * Ulozenie run quality
   * @param quality - Kvalita
   */
  public saveRunQuality(quality: number): void {

    console.log('saving run quality')
    this.runQualitySaved = true
    this.saveTaskValue(Tasks.runQuality, quality)

  }

  /**
   * Hlavna privatna metoda, ktora riesi UI zmeny a trainingManager classu
   * @param name - meno tasku
   * @param value - Vyhodnotena hodnota tasku
   */
  public saveTaskValue(name: Tasks, value: number): void {

    if (!modes.isTrainingMode()) return

    value = Math.ceil(value * 10000) / 10000

    this.changeUI(name, value)
    trainingManager.setTaskValue(name, value)

  }

  /**
   * Zmena UI
   * @param name - meno tasku
   * @param value - Vyhodnotena hodnota tasku
   */
  private changeUI(name: Tasks, value: number) {

    if (!modes.isTrainingMode()) return
    console.log(`UI zmeny ${name} ma hodnotu ${value}`)
    const percent = Math.ceil(value * 100)
    const tpTask = Math.ceil(Math.round((trainingManager.getTpPerTask() * value * 10) + Number.EPSILON) / 10)
    console.log('tp task:', tpTask)

    if (tpTask !== undefined) {

      store.commit('TrainingState/EDIT_TASK', {
        text: `trainingTask${requestManager.disciplineID}-${name}`,
        percent: String(percent),
        points: String(tpTask),
        showPoints: true
      })

    }

  }

  /**
   * Reset
   */
  public reset(): void {

    console.log('RESET TRAINING')
    // TODO ZMENIT NA TRAINING TASK
    this.runQualitySaved = false
    trainingManager.resetAll()

  }

}

export const trainingTasks = new TrainingTasks()

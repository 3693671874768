import store from '@/store'
import {
  minigameConfig,
  modes,
  type TutorialObjective
} from '@powerplay/core-minigames'

export class TutorialObjectives {

  private objectives: TutorialObjective[] = []

  /** pocitadlo failed startov */
  public falseStartCount = 0

  /** cas dosiahnuty v druhom behu */
  public time = minigameConfig.dnfValue

  public setObjectives(objectives: TutorialObjective[]): this {

    this.objectives = objectives
    store.commit('TutorialState/SET_OBJECTIVES', objectives)

    return this

  }

  public passObjective(objectiveId: string): void {

    if (!modes.isTutorial() || this.objectives.length <= 0) return

    const objective = this.getObjectiveById(objectiveId)
    if (!objective) return

    objective.failed = false
    objective.passed = true
    store.commit('TutorialState/SET_OBJECTIVES', this.objectives)

  }

  public failObjective(objectiveId: string): void {

    if (!modes.isTutorial() || this.objectives.length <= 0) return

    const objective = this.getObjectiveById(objectiveId)
    if (!objective) return

    objective.passed = false
    objective.failed = true
    store.commit('TutorialState/SET_OBJECTIVES', this.objectives)

  }

  public update(): void {

    if (!modes.isTutorial() || this.objectives.length <= 0) return
    this.checkIfAllObjectivesPassed()

  }

  /**
   * Zistenie, ci su vsetky ciele splnene
   * @returns True, ak su splnene vsetky ciele
   */
  public isAllObjectivesPassed(): boolean {

    let allPassed = true
    this.objectives.forEach((objective) => {

      if (!this.checkIfObjectivePassed(objective)) allPassed = false

    })

    return allPassed

  }

  /**
   * Zistenie, ci su nejake ciele splnene
   * @returns True, ak su splnene vsetky ciele
   */
  public isSomeObjectivesPassed(): boolean {

    let somePassed = false
    this.objectives.forEach((objective) => {

      if (this.checkIfObjectivePassed(objective)) somePassed = true

    })

    return somePassed

  }

  /**
   * Zistenie, ci su vsetky ciele splnene
   * @returns True, ak su splnene vsetky ciele
   */
  private checkIfAllObjectivesPassed(): boolean {

    // logika
    const passedArray = this.objectives.map(this.checkIfObjectivePassed)
    if (passedArray.includes(false)) return false
    if (this.objectives.length < 1) return false

    this.actionOnPassed()
    return true

  }

  private actionOnPassed(): void {

    // koniec hry

  }

  private checkIfObjectivePassed(objective?: TutorialObjective): boolean {

    return objective !== undefined && objective.passed

  }

  public checkIfObjectivePassedById(id: string): boolean {

    return this.checkIfObjectivePassed(this.getObjectiveById(id))

  }

  private getObjectiveById(id: string): TutorialObjective | undefined {

    const objectiveIndex = this.objectives.findIndex((objective: TutorialObjective) => {

      return objective.id === id

    })

    if (objectiveIndex === -1) return undefined

    return this.objectives[objectiveIndex]

  }

}
export const tutorialObjectives = new TutorialObjectives()

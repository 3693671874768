import {
  opponentConfig,
  tutorialConfig
} from '@/app/config'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import {
  MaterialsNames,
  ModelsNames
} from '@/app/types'
import {
  game,
  gsap,
  THREE,
  playersManager,
  type PlayerInfo,
  modes
} from '@powerplay/core-minigames'
import { player } from '../player'
import { materialsConfig } from '@/app/config/materialsConfig'
import { Athlete } from '..'

/** Trieda pre spravu protihraca */
export class Opponent extends Athlete {

  /** pocet framov do startu */
  private delayedStartFrames = 0

  /**
   * Konstruktor
   * @param uuid - UUID spera
   * @param materialIndex - index materialu
   */
  public constructor(public uuid: string, private materialIndex: number) {

    super(uuid)

  }

  /**
   * Vratenie objektu atleta
   * @returns Objekt atleta
   */
  protected getObject(): THREE.Object3D {

    const sex = playersManager.getPlayerById(this.uuid)?.sex
    let objectForClone
    if (playersManager.getPlayer().sex !== sex) objectForClone = game.getObject3D('athlete_opponent')

    const athleteObject = objectForClone ?
      game.cloneSkeleton(objectForClone) :
      game.cloneSkeleton(player.athleteObject)

    materialsConfig[ModelsNames.athlete]?.meshesArray?.forEach((meshName) => {

      const opponentMesh = athleteObject.getObjectByName(meshName) as THREE.Mesh
      if (!opponentMesh) return
      meshName += `_opponent_${this.materialIndex}`
      opponentMesh.name = meshName

      // musime nastavit material
      opponentMesh.material = game.materialsToUse.basic
        .get(MaterialsNames.athleteOpponentPrefix + this.materialIndex) as THREE.MeshBasicMaterial

    })

    return athleteObject

  }

  /**
   * Vytvorenie protihraca
   * @param trackIndex - Index drahy
   */
  public create(trackIndex: number): void {

    console.log('vytvaram protihraca...', this.uuid)

    const info = playersManager.getPlayerById(this.uuid) as PlayerInfo
    const sufix = playersManager.getPlayer().sex !== info.sex ?
      '_opponent' :
      ''

    super.create(trackIndex, `Opponent${ this.uuid}`, ModelsNames.athlete + sufix)

    this.animationsManager.pauseAll()
    gsap.to({}, {
      onComplete: () => {

        this.animationsManager.unpauseAll()

      },
      duration: 1
    })

    // vygenerujeme pole hodnot pre speedbar
    this.generateSpeedbarValues()

    // vygenerujeme percento na trati, kde bude lunge
    this.worldEnvLinesManager.generateLungePosition()

  }

  /**
   * Vygenerovanie hodnot pre speedbar
   */
  private generateSpeedbarValues(): void {

    const {
      valuesToGenerate, valueDefault, valueSpecial
    } = opponentConfig.speedbar

    let valueDefaultValue = valueDefault
    let everyX = valueSpecial.everyX
    let valueSpecialFrom = valueSpecial.from
    let valueSpecialTo = valueSpecial.to

    const strengthOpp = playersManager.getPlayerById(this.uuid)?.attribute.total || 0
    const strengthPlayer = playersManager.getPlayer().attribute.total || 0

    valueSpecialFrom = Math.round(valueSpecialFrom - (strengthOpp - strengthPlayer) / 15)
    valueSpecialFrom = Math.max(valueSpecialFrom, 194)
    valueSpecialFrom = Math.min(valueSpecialFrom, 199)
    valueSpecialTo = Math.round(valueSpecialTo - (strengthOpp - strengthPlayer) / 15)
    valueSpecialTo = Math.max(valueSpecialTo, 201)
    valueSpecialTo = Math.min(valueSpecialTo, 206)

    if (strengthOpp <= 100) {

      everyX = Math.round(strengthOpp / 100 + 4)

    } else if (strengthOpp > 100 && strengthOpp <= 200) {

      everyX = Math.round((strengthOpp - 100) / 100 + 6)

    }

    everyX = Math.round(everyX + (strengthOpp - strengthPlayer) / 30)
    everyX = Math.min(everyX, 10)
    everyX = Math.max(everyX, strengthOpp > 100 ? 6 : 4)

    if (modes.isTutorial()) {

      valueDefaultValue = tutorialConfig.opponent.valueDefault
      valueSpecialFrom = tutorialConfig.opponent.valueSpecial.from
      valueSpecialTo = tutorialConfig.opponent.valueSpecial.to
      everyX = tutorialConfig.opponent.valueSpecial.everyX

    }
    // vygenerujeme x hodnot speedbaru
    for (let i = 0; i < valuesToGenerate; i += 1) {

      let value = 0

      // kazda x-ta hodnota bude
      value = i % everyX === everyX - 1 ?
        THREE.MathUtils.randInt(valueSpecialFrom, valueSpecialTo) :
        valueDefaultValue

      this.velocityManager.speedbarValues.push(value)

    }

    console.log(`Opponent ${this.uuid
    } everyX: ${everyX
    }, valueSpecialFrom: ${valueSpecialFrom
    }, valueSpecialTo: ${valueSpecialTo
    }`)

    console.log(`opponent strength: ${strengthOpp}, player strength: ${strengthPlayer}`)

    console.log(`generated speedbar values opponent ${this.uuid}:`, this.velocityManager.speedbarValues)

  }

  /**
   * Aktualizovanie hraca pred vykonanim fyziky
   * @param frame - aktualny frame
   * @param inTop3 - Ci je super v top3
   */
  public updateBeforePhysics(frame = 0, inTop3: boolean): void {

    super.updateBeforePhysics(disciplinePhasesManager.actualPhase, inTop3)

    // skontrolujeme, ci netreba odstartovat
    this.checkDelayedStart(frame)

  }

  /**
   * Vymazanie vsetkych callbackov z animations managera
   */
  public removeCallbacksFromAllAnimations(): void {

    this.animationsManager.removeCallbacksFromAllAnimations()

  }

  /**
   * Nastavenie reakcnych casov
   */
  public setDelayedStartFrames(): void {

    const { from, to } = opponentConfig.startFrames
    let fromByStrength = from
    let toByStrength = to

    const strength = playersManager.getPlayerById(this.uuid)?.attribute.total || 0
    if (strength <= 100) {

      fromByStrength = 6
      toByStrength = 9

    } else if (strength > 100 && strength <= 200) {

      fromByStrength = 5
      toByStrength = 8

    }
    this.delayedStartFrames = Math.round(Math.random() * (toByStrength - fromByStrength) + fromByStrength)

    if (modes.isTutorial()) this.delayedStartFrames = tutorialConfig.opponent.reactionTime

    console.log(`Protihracov ${this.uuid} reakcny cas (frame) = ${this.delayedStartFrames}`)
    // this.actualState = OpponentState.start

  }

  /**
   * Skontrolovanie oneskoreneho startu podla reakcneho casu
   * @param frame - aktualny frame
   */
  private checkDelayedStart(frame: number): void {

    if (this.delayedStartFrames !== frame) return

    disciplinePhasesManager.phaseStart.manageFirstStarted()
    this.start()

  }

  /**
   * Spravanie pri dosiahnuti ciela
   * @param actualPosition - Aktualna pozicia v metroch
   * @param lastPosition - Posledna pozicia v metroch
   * @param finishPosition - Pozicia ciela v metroch
   */
  public finishReached(actualPosition: number, lastPosition: number, finishPosition: number): void {

    super.finishReached(actualPosition, lastPosition, finishPosition)

    if (!disciplinePhasesManager.phaseFinish.dailyLeagueSetResultsOpponentsFreeze) {

      playersManager.setPlayerResultsById(this.uuid, this.finalTime)

    }
    disciplinePhasesManager.phaseFinish.finishedOpponents += 1

  }

  /**
   * reset atleta
   */
  public reset(): void {

    super.reset()
    this.generateSpeedbarValues()

  }

}

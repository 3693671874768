import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface TextMessageState {
  showFirstLine: boolean,
  showSecondLine: boolean,
  firstLineText: string,
  firstLineTextType: number,
  secondLineTextType: number,
  secondLineLeftNumber: number,
  showMessage: boolean,
  showType: number
}

const initialState = () => ({
  showFirstLine: false,
  showSecondLine: false,
  firstLineText: '',
  firstLineTextType: 0,
  secondLineTextType: 0,
  secondLineLeftNumber: 0,
  showMessage: false,
  showType: 0
})

const textMessageState: Module<TextMessageState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getTextMessageState: (moduleState: TextMessageState) => moduleState,
  },

  mutations: {
    RESET: (moduleState: TextMessageState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: TextMessageState, newState: TextMessageState) => {

      moduleState.showFirstLine = newState.showFirstLine
      moduleState.showSecondLine = newState.showSecondLine
      moduleState.firstLineText = newState.firstLineText
      moduleState.firstLineTextType = newState.firstLineTextType
      moduleState.secondLineTextType = newState.secondLineTextType
      moduleState.secondLineLeftNumber = newState.secondLineLeftNumber
      moduleState.showMessage = newState.showMessage
      moduleState.showType = newState.showType

    }
  }
}

export default textMessageState

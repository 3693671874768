/** Konfig pre start */
export const startConfig = {

  /** Pocet sekund, kolko bude maximalna odchylka od zaciatku stavu set, aby nesli vsetci naraz */
  maxRandomDelaySet: 0.4,

  /** Max pocet frameov pre hlasku */
  startMessageFrames: {
    perfect: 5,
    excellent: 8,
    good: 10
  },

  /** nastavenie pre false start */
  falseStart: {

    /** Minimalna rychlost animacii */
    animationMinSpeed: 0.75,

    /** Minimalna rychlost v m/s */
    minSpeed: 2,

    /** Hodnota v m/s o kolko sa bude znizovat rychlost pri spomalovani */
    decreaseSpeedValue: 0.5

  },

  /** Ci bude zobrazeny debug startovaci bar */
  debugStartBar: false

}
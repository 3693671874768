import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface LungeState {
  showLungeInfo: boolean,
  text: string,
  fill: number,
  color: string,
  messageType: string,
  showInfo: boolean
}

const initialState = () => ({
  showLungeInfo: false,
  text: '',
  fill: 0,
  color: '',
  messageType: '',
  showInfo: false
})

const lungeState: Module<LungeState, RootState> = {
  namespaced: true,
  state: initialState(),

  getters: {
    getLungeState: (moduleState: LungeState) => moduleState,
  },

  mutations: {
    RESET: (moduleState: LungeState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: LungeState, newState: LungeState) => {

      moduleState.showLungeInfo = newState.showLungeInfo
      moduleState.text = newState.text
      moduleState.fill = newState.fill
      moduleState.color = newState.color
      moduleState.messageType = newState.messageType
      moduleState.showInfo = newState.showInfo

    },
  }
}

export default lungeState

import type { Module } from 'vuex'
import type { RootState } from '../index'
import type { DisplayMessage } from '@/app/types'


export interface StartMessageState {
  showMessage1: boolean,
  messageText1: string,
  messageColor1: number,
  messageType1: number,
  showMessage2: boolean,
  messageText2: string,
  messageColor2: number,
  messageType2: number
}

const initialState = () => ({
  showMessage1: false,
  messageText1: '',
  messageColor1: 0,
  messageType1: 0,
  showMessage2: false,
  messageText2: '',
  messageColor2: 0,
  messageType2: 0
})

const startMessageState: Module<StartMessageState, RootState> = {
  namespaced: true,
  state: initialState(),

  getters: {
    getStartMessageState: (moduleState: StartMessageState) => moduleState
  },

  mutations: {
    RESET: (moduleState: StartMessageState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE_1: (moduleState: StartMessageState, message: DisplayMessage) => {

      moduleState.showMessage1 = message.showMessage || false
      moduleState.messageText1 = message.message || ''
      moduleState.messageColor1 = message.color || 0
      moduleState.messageType1 = message.messageType || 0

    },
    SET_STATE_2: (moduleState: StartMessageState, message: DisplayMessage) => {

      moduleState.showMessage2 = message.showMessage || false
      moduleState.messageText2 = message.message || ''
      moduleState.messageColor2 = message.color || 0
      moduleState.messageType2 = message.messageType || 0

    }
  }
}

export default startMessageState

/** Konfig pre nastavenie rychlosti hraca */
export const velocityConfig = {

  /** Speed bar veci */
  speedBar: {

    /** minimalna hodnota speed baru */
    minValue: 0,

    /** minimalna hodnota speed baru po starte */
    minValueAfterStart: 100,

    /** maximalna hodnota speed baru */
    maxValue: 220,

    /** Minimalna idealna hodnota */
    minIdealValue: {
      min: 110,
      max: 190
    },

    /** Hodnoty pre spravu min idealu */
    minIdeal: {
      /** Znizovanie min idealu */
      decrease: {
        frames: 2,
        value: -1
      },
      /** Zvysovanie min idealu */
      increase: [
        { limitMax: 145,
          frames: 1,
          value: 1 },
        { limitMax: 166,
          frames: 2,
          value: 1 },
        { limitMax: 180,
          frames: 5,
          value: 1 },
        { limitMax: 190,
          frames: 10,
          value: 1 }
      ]
    },

    /** Maximalna idealna hodnota */
    maxIdealValue: 200,

    /** hodnota pri zvysovani hodnoty speed baru */
    stepAdd: [
      { limitMax: 30,
        baseAdd: 120,
        minAdd: 120,
        maxAdd: 120 },
      { limitMax: 140,
        baseAdd: 30,
        minAdd: 30,
        maxAdd: 45 },
      { limitMax: 180,
        baseAdd: 10,
        minAdd: 10,
        maxAdd: 40 },
      { limitMax: 999,
        baseAdd: 4,
        minAdd: 10,
        maxAdd: 20 }
    ],

    /** hodnota pri znizovani hodnoty speed baru */
    stepRemove: [
      { limitMax: 145,
        value: 3 },
      { limitMax: 166,
        value: 3 },
      { limitMax: 180,
        value: 4 },
      { limitMax: 190,
        value: 4 }
    ],

    /** Freeznutie po kliku */
    freeze: [
      { limitMax: 145,
        frames: 4 },
      { limitMax: 166,
        frames: 5 },
      { limitMax: 180,
        frames: 6 },
      { limitMax: 190,
        frames: 7 }
    ]

  },

  /** Veci ohladom rychlosti v cieli */
  speedOnEnd: {

    /** Minimalna rychlost pre emociu */
    minSpeed: 4,

    /** Minimalna rychlost pre walk */
    minSpeedWalk: 3,

    /** Pocet frameov, za kolko sa dostane na min speed pri emocii */
    framesToMinSpeed: 60,

    /** Pocet frameov do uplneho zastavenia */
    framesToStop: 25,

    /** Randomizovanie konca, tj kolko frameov moze byt menej alebo viac oproti prvemu spomalovaniu */
    randomizeEnd: {

      minus: 0,
      plus: 60

    }

  }

}

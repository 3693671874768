<template>
  <div
    v-if="startPhaseState.showPhase"
    class="phase-start"
  >
    <player-info-avatar
      v-if="startPhaseState.showName"
      :texts="getTexts"
      :is-training="isTraining"
      :background-id="getAvatarBackgroundId"
      :background-ext="getAvatarBackgroundExt"
      :avatar-id="getAvatarId"
      :avatar-ext="getAvatarExt"
      :sex="getSex"
      :second-line-title="isTraining ? 'highScore' : 'personalBest'"
    />
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import {
  playersManager,
  modes,
  trainingManager,
  minigameConfig,
  AvatarImageExtensions,
  timeManager
} from '@powerplay/core-minigames'
import { PlayerInfoAvatar } from '@powerplay/core-minigames-ui-ssm'

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    PlayerInfoAvatar
  },
  computed: {
    ...mapGetters({
      startPhaseState: 'StartPhaseState/getStartPhaseState'
    }),
    getTexts() {

      const personalBest = playersManager.getPlayer().personalBest
      let additionlInfo = timeManager.getTimeInFormatFromSeconds(personalBest, 2)
      if (personalBest === 0 || personalBest >= minigameConfig.dnfValue) {

        additionlInfo = '-'

      }
      if (this.isTraining) {

        additionlInfo = trainingManager.bestScore.toString()

      }
      return {
        name: playersManager.players[0].name,
        country: playersManager.players[0].country.toLowerCase(),
        countryString: playersManager.players[0].countryString,
        additionlInfo,
        attempt: this.startPhaseState.attempt
      }

    },
    isTraining() {

      return modes.isTrainingMode()

    },
    getAvatarBackgroundId() {

      const avatarBg = playersManager.getPlayer().avatarBg
      return avatarBg === undefined ? 1 : Number(avatarBg)

    },
    getAvatarBackgroundExt() {

      // zatial takto, ak by do buducna trebalo, vieme doplnit funkcionalitu
      return AvatarImageExtensions.png

    },
    getAvatarId() {

      const avatar = playersManager.getPlayer().avatar
      return avatar === undefined ? 1 : Number(avatar)

    },
    getAvatarExt() {

      const playerInfo = playersManager.getPlayer()
      let avatarExt = playerInfo.avatarExt
      if (avatarExt === undefined) {

        avatarExt = playerInfo.avatar === 104 ?
          AvatarImageExtensions.gif :
          AvatarImageExtensions.png

      }
      return avatarExt

    },
    getSex() {

      return playersManager.getPlayer().sex

    }
  }
})
</script>

<style lang="less" scoped>
.phase-start {
  position: absolute;
  bottom: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
}
</style>

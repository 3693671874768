import {
  PlayerSex,
  PlayerRace,
  minigameConfig,
  HairColorTypes
} from '@powerplay/core-minigames'

/**
 * Konfig pre kameru pre training
 */
export const tutorialConfig = {

  opponent: {

    /** reakcny cas vo framoch */
    reactionTime: 9,

    /** default hodnota pre generovanie */
    valueDefault: 200,

    /** specialna hodnota pre generovanie */
    valueSpecial: {

      from: 203,
      to: 203,

      /** Kazda x-ta hodnota bude specialna */
      everyX: 2

    },

    /** Kazdy x-ty frame sa bude menit hodnota speedbaru */
    changeValueEveryX: 6

  },

  /** kedy ma dat hracovi lunge v prvom bhu */
  playerLungeFirstRun: 3,

  fakeOpponent: {
    uuid: 'uuidOpponent1',
    name: 'Johannes',
    country: 'nor',
    countryString: 'nor',
    hair: 1,
    hairColor: HairColorTypes.black,
    sex: PlayerSex.male,
    race: PlayerRace.white,
    experiences: 0,
    attribute: {
      'base': 0,
      'total': 0
    },
    personalBest: 0,
    playable: false,
    resultsArr: [
      {
        main: minigameConfig.dnfValue
      }
    ]
  }



}

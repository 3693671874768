import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/**
 * Konfig pre kameru pre training
 */
export const trainingConfig = {

  /** Kvalita startu podla GD - hodnoty su po frameoch */
  startQualities: [
    0,
    0, // 1
    0,
    100,
    99,
    98, // 5
    95,
    90,
    85,
    80,
    70, // 10
    60,
    50,
    25,
    15,
    5 // 15
  ],

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(0.19059371948, 1.15008175373, -4.4945793),
      endPosition: new THREE.Vector3(0.19059371948, 1.15008175373, -4.4945793),
      startRotation: new THREE.Vector3(1.5403486, -0.006517394, -3.09213628),
      endRotation: new THREE.Vector3(1.5403486, -0.006517394, -3.09213628),
      duration: 3,
      notSkippableFrames: 20,
      fov: 36.4,
      offsetCamera: true
    },
    [CameraStates.intro2]: undefined,
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(65.35472869873047, 29.06309700012207, -63.67209243774414),
      endPosition: new THREE.Vector3(52.92066955566406, 15.357963562011719, -68.88593292236328),
      startRotation: new THREE.Vector3(1.8531298163809546, 0.16892826638428243, -2.774806166746781),
      endRotation: new THREE.Vector3(1.8250801965805064, 0.1218453008704289, -2.88817661305781),
      duration: 5
    },
    [CameraStates.disciplineIntro]: {
      startPosition: new THREE.Vector3(0.19059371948, 1.15008175373, -4.4945793),
      endPosition: new THREE.Vector3(-0.35896682739, 1.1644126179864, -4.0386695861),
      startRotation: new THREE.Vector3(1.5403486, -0.006517394, -3.09213628),
      endRotation: new THREE.Vector3(1.548322, 0.005684515, 3.05518974),
      duration: 3,
      fov: 36.4,
      offsetCamera: true
    },
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: undefined,
    [CameraStates.static]: undefined
  },
  distanceFromGround: 1.5

}

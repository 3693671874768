import { type DisplayMessage } from '@/app/types'
import store from '@/store'
import { modes } from '@powerplay/core-minigames'

export class StartPhaseStateManager {

  private store = store

  private textMessagesLinesFinishedEmits = [false, false]

  public disableInputs(): void {

    this.store.commit('InputsState/SET_DISABLED', true)
    this.store.commit('ActionButtonState/SET_IS_ACTION_DISABLED', false)

  }

  public enableInputs(disabled = false): void {

    this.store.commit('InputsState/SET_VISIBLE', true)
    this.store.commit('InputsState/SET_DISABLED', disabled)

  }

  public showStartMessageInUI(message: DisplayMessage, componentType: number): void {

    this.store.commit(`StartMessageState/SET_STATE_${componentType}`, {
      showMessage: true,
      message: message.message,
      color: message.color,
      messageType: message.messageType
    })

  }

  public postIntroUiState(): void {

    this.store.commit('UiState/SET_STATE', {
      showTimeKeeper: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })
    this.store.commit('MovementState/SET_HORIZONAL', true)
    this.store.commit('InputsState/SET_DISABLED', false)

  }

  public finishPhaseUiState(): void {

    this.store.commit('UiState/SET_STATE', {
      showTimeKeeper: true,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })

  }

  public enableStartInputs(): void {

    console.log('enable start inputs')
    this.store.commit('InputsState/SET_DISABLED', false)
    this.store.commit('ActionButtonState/SET_START_BUTTON', true)
    this.store.commit('ActionButtonState/SET_IS_ACTION_DISABLED', false)

  }

  public disableStartMessageInUI(message: DisplayMessage, messageType: number): void {

    this.store.commit(`StartMessageState/SET_STATE_${messageType}`, {
      showMessage: false,
      message: message.message,
      color: message.color,
    })

  }

  /**
   * Schovanie text message hlasky
   * @param lineNumber - Cislo riadku, ktore malo emit konca
   */
  public hideTextMessage(lineNumber: number): void {

    this.textMessagesLinesFinishedEmits[lineNumber - 1] = true

    if (this.textMessagesLinesFinishedEmits[0] && this.textMessagesLinesFinishedEmits[1]) this.hideAllTextMessages()

  }

  /**
   * Schovanie vsetkych message hlasok
   */
  public hideAllTextMessages(): void {

    store.commit('TextMessageState/SET_STATE', {
      showFirstLine: false,
      showSecondLine: false,
      firstLineText: '',
      firstLineTextType: 0,
      secondLineTextType: 0,
      secondLineLeftNumber: 0,
      showMessage: false,
      showType: 0
    })

  }

  /**
   * Resetovanie text message dokoncenych emitov
   * @param activeFirst - ci je aktivny prvy riadok
   * @param activeSecond - ci je aktivny druhy riadok
   */
  public resetTextMessageFinishedEmits(activeFirst: boolean, activeSecond: boolean): void {

    this.hideAllTextMessages()
    this.textMessagesLinesFinishedEmits = [!activeFirst, !activeSecond]

  }

}

export const startPhaseStateManager = new StartPhaseStateManager()
